import { BASE_FRAME_NAMES, DEFAULT_BASE_FRAME_SHAPE, FRAME_COLOR_HANDLES, FRAME_COLORS, RX_FIELDS } from '@constants';

const ESCAPABLE_CHARACTERS = ['\\', '.', '[', ']', '{', '}', '(', ')', '<', '>', '*', '+', '-', '=', '!', '?', '^', '$', '|'];

export const capitalizeEachWord = (words: string) => {
	return words?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
};

/**
 * Capitalizes first letters of words in string.
 * @param {string} str String to be modified
 * @param {boolean=false} lower Whether all other letters should be lowercased
 * @return {string}
 * @usage
 *   capitalize('fix this string');     // -> 'Fix This String'
 *   capitalize('javaSCrIPT');          // -> 'JavaSCrIPT'
 *   capitalize('javaSCrIPT', true);    // -> 'Javascript'
 */
export const capitalize = (str, lower = false) =>
	(lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());

/**
 * Combine Accumulatively
 *
 * when segments = ['1','2','3']
 * returns ['1','1/2','1/2/3']
 *
 * @param segments
 * @returns {array}
 */
export const combineAccumulatively = (segments: string[]): string[] => {
	return segments.reduce((acc, cur, curIndex) => {
		const last = curIndex > 1 ? acc[curIndex - 1] : '';
		const newPath = last + '/' + cur;
		acc.push(newPath);
		return acc;
	}, []);
};

export const formatColorName = ({ name, char }) => {
	const array = name.split(' ');
	return array.length > 1 ? array.join(char) : name;
};

/**
 * Returns an input string in lower case, with spaces and forward slashes replaced by "-"
 * @example handelize("Some Words") -> "some-words"
 * @example handelize("some/kind/of/link") -> "some-kind-of-link"
 */
export const handelize = (str: string) => {
	if (!str) return;
	return str
		.replace('/', ' ')
		.trim()
		.replace(/\/|\s+/g, '-')
		.toLowerCase();
};

/**
 * Returns an input string in camel case.
 */
export const camelize = (str: string) => {
	return str
		.replaceAll('_', ' ')
		.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
			return index === 0 ? word.toLowerCase() : word.toUpperCase();
		})
		.replace(/\s+/g, '');
};

/**
 * Returns an input string in snake case.
 */
export const snakeCase = (str: string) => {
	return str.replaceAll(' ', '_').replace(/\s+/g, '');
};

/**
 * Removes "the" from Product Title. 2nd Param defaults to __true__
 * @example getbaseName("The Kirby") -> "kirby"
 * @example getbaseName("The Kirby", false) -> "Kirby"
 */
export const getBaseName = (name = DEFAULT_BASE_FRAME_SHAPE, makeLowerCase = true): (typeof BASE_FRAME_NAMES)[number] => {
	if (!name.includes(' ')) return name as (typeof BASE_FRAME_NAMES)[number];
	if (name.includes('Every')) return name.split(' ')[0] as (typeof BASE_FRAME_NAMES)[number];
	const baseName = name.split(' ')[1];

	if (makeLowerCase) return baseName.toLowerCase() as (typeof BASE_FRAME_NAMES)[number];
	return baseName as (typeof BASE_FRAME_NAMES)[number];
};

/**
 *  @example
 * 	Jane -> "Jane's"
 * 	James -> "James'"
 */
export const makePossessive = (str: string) => {
	return str.slice(-1) === 's' ? `${str}'` : `${str}'s`;
};

/**
 *  Split a string in a specific index.
 *  @example
 *  splitAt(2, ' ', 'This is an example sentence') -> ['This is', 'an example sentence']
 */
export const splitAt = (index: number, character: string, str: string) => {
	const strArray = str.split(character);
	const firstPart = strArray.slice(0, index).join(character);
	const secondPart = strArray.slice(index).join(character);
	return [firstPart, secondPart];
};

/**
 * Finds the first substring from an array of strings; case-insensitive.
 * @example
 * Test: 'Shop Men Men Women Kids' -> ['women', 'men', 'kids'], returns ONLY 'Women'
 *
 * @param criteria
 * @param str
 * @returns array
 */
export const findSubstringOnce = ({ criteria, str }: { criteria: string[] | string; str: string }) => {
	let criteriaStr = Array.isArray(criteria) ? criteria.join('|') : criteria;

	if (typeof criteria === 'string') {
		criteriaStr = [...criteriaStr]
			.map(c => {
				if (ESCAPABLE_CHARACTERS.includes(c)) return `\\${c}`;
				return c;
			})
			.join('');
	}

	const test = new RegExp(`\\b(?:${criteriaStr})\\b`, 'im');
	return str.match(test);
};

export const orderSelectedRxFields = (selectedFields: string[]) => {
	return selectedFields.sort((a, b) => RX_FIELDS.indexOf(a) - RX_FIELDS.indexOf(b));
};

export const getFrameColorFromHandle = (handle: FRAME_COLOR_HANDLES): FRAME_COLORS => {
	const handleToColorMap: Record<FRAME_COLOR_HANDLES, FRAME_COLORS> = {
		[FRAME_COLOR_HANDLES.MIX_BLACK_GOLD]: FRAME_COLORS.MIX_BLACK_GOLD,
		[FRAME_COLOR_HANDLES.MIX_CRYSTAL_SILVER]: FRAME_COLORS.MIX_CRYSTAL_SILVER,
		[FRAME_COLOR_HANDLES.BLACK]: FRAME_COLORS.BLACK,
		[FRAME_COLOR_HANDLES.BLUE_CLEAR]: FRAME_COLORS.BLUE_CLEAR,
		[FRAME_COLOR_HANDLES.BLUE_TORTOISE]: FRAME_COLORS.BLUE_TORTOISE,
		[FRAME_COLOR_HANDLES.CRYSTAL_CLEAR]: FRAME_COLORS.CRYSTAL_CLEAR,
		[FRAME_COLOR_HANDLES.PINK_CLEAR]: FRAME_COLORS.PINK_CLEAR,
		[FRAME_COLOR_HANDLES.TORTOISE]: FRAME_COLORS.TORTOISE,
		[FRAME_COLOR_HANDLES.GRAY_CLEAR]: FRAME_COLORS.GRAY_CLEAR,
		[FRAME_COLOR_HANDLES.HONEY_CLEAR]: FRAME_COLORS.HONEY_CLEAR,
		[FRAME_COLOR_HANDLES.LAVENDER_CLEAR]: FRAME_COLORS.LAVENDER_CLEAR,
	};

	return handleToColorMap[handle];
};

export const getFrameHandleFromColor = (color: FRAME_COLORS): FRAME_COLOR_HANDLES => {
	switch (color) {
		case FRAME_COLORS.MIX_BLACK_GOLD:
			return FRAME_COLOR_HANDLES.MIX_BLACK_GOLD;
		case FRAME_COLORS.MIX_CRYSTAL_SILVER:
			return FRAME_COLOR_HANDLES.MIX_CRYSTAL_SILVER;
		default:
			return handelize(color) as FRAME_COLOR_HANDLES;
	}
};
