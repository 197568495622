import { useEffect, useState } from 'react';
import { COLLECTION_LISTS } from '@constants/contentful';
import { CollectionLozenge, NormalizedProduct } from '@ts/product';
import { useCollectionsQuery } from '@utils/react-query';
import { PRODUCT_TYPES, RENAME_PRODUCT_TYPES } from '@constants';

export const useLozengeData = (product: NormalizedProduct) => {
	const { data: activeCollections } = useCollectionsQuery(COLLECTION_LISTS.ACTIVE, {
		queryKey: 'active-collections',
		type: 'active',
		withProducts: false,
	});
	const [lozengeData, setLozengeData] = useState<CollectionLozenge>(null);
	useEffect(() => {
		if (activeCollections) setLozengeData(getLozengeData(product, activeCollections));
	}, [activeCollections, product]);

	return lozengeData;
};

// Helper Function
function getLozengeData(product: NormalizedProduct, activeCollections) {
	let collectionData = null as CollectionLozenge;

	if (product?.type?.includes(PRODUCT_TYPES.TOP_FRAME)) {
		try {
			const productType = RENAME_PRODUCT_TYPES[product.type] ?? product.type;
			const collection = activeCollections[productType];

			const lozengeData = collection?.metafields ?? null;
			collectionData = {
				...lozengeData,
				title: collection?.title,
				lozengeHandle: lozengeData?.lozengeHandle ?? collection?.handle,
			};
		} catch (error) {
			console.error(`Error getting Lozenge data for product: ${product.name}`);
			console.error(error);
		}
	}
	return collectionData ?? null;
}

export default useLozengeData;
