/* eslint-disable max-lines */
import { ButtonHTMLAttributes, forwardRef, HTMLAttributeAnchorTarget, LegacyRef, useCallback } from 'react';
import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Chevron, Close } from '@components';
import { ComponentProps } from '@ts/components';
import { formatCurrency } from '@utils/shopify';
import { LOCALE_CODES } from '@constants';
import linkStyles from '../Link/Link.module.scss';
import Loading from '../Loading';
import styles from './Button.module.scss';

export type ButtonProps = ComponentProps<HTMLButtonElement> & {
	backgroundColor?: string;
	border?: string;
	chevronColor?: string;
	chevronDirection?: 'up' | 'down' | 'right' | 'left';
	chevronPosition?: 'front' | 'back';
	closePosition?: 'front' | 'back';
	color?: 'green' | 'blue' | 'white' | 'transparent-light' | 'transparent-dark';
	disabled?: boolean;
	extraClasses?: string;
	fullWidth?: boolean;
	fitContent?: boolean;
	href?: HTMLAnchorElement['href'];
	label?: string;
	linkStyle?: boolean;
	margin?: string;
	onClick?: (arg?) => void;
	onClose?: (arg?) => void;
	padding?: string;
	removeEffects?: boolean;
	size?: 'xsmall' | 'small' | 'medium' | 'large';
	spaceBetween?: boolean;
	withChevron?: boolean;
	withClose?: boolean;
	withPrice?: boolean;
	price?: number;
	currencyCode?: string;
	type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
	loadingText?: string;
	showSpinner?: boolean;
	title?: string;
	compareAtPrice?: number | null;
	target?: HTMLAttributeAnchorTarget | undefined;
	icon?: JSX.Element;
	showPriceWithLabel?: boolean;
	['data-testid']?: string;
};

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
	(
		{
			backgroundColor,
			border,
			chevronColor = 'inherit',
			chevronDirection,
			chevronPosition = 'back',
			closePosition = 'back',
			children,
			color = 'green',
			disabled,
			extraClasses,
			fullWidth,
			fitContent,
			href = null,
			label = '',
			linkStyle,
			margin,
			onClick,
			onClose,
			padding,
			removeEffects,
			size = 'medium',
			spaceBetween = false,
			style,
			withChevron = false,
			withClose = false,
			withPrice = false,
			price = null,
			compareAtPrice = null,
			type = 'button',
			loadingText,
			showSpinner,
			icon,
			currencyCode = 'USD',
			showPriceWithLabel = false,
			dataTags,
			...props
		}: ButtonProps,
		ref
	) => {
		const { locale } = useRouter();
		const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;
		const defaultClasses = [styles['button'], styles[`button--${size}`], styles[`button--${color}`]].join(' ');
		const linkStyleClasses = cn(linkStyles['link'], [styles['button--link']], extraClasses, {
			[linkStyles['link--small']]: size === 'small',
			[linkStyles['link--medium']]: size === 'medium',
			[linkStyles['link--xsmall']]: size === 'xsmall',
		});
		const buttonClasses = linkStyle
			? linkStyleClasses
			: cn(defaultClasses, extraClasses, {
					[styles['button--disabled']]: disabled,
				});
		const styleOverrides = {
			...(backgroundColor ? { backgroundColor } : {}),
			...(fullWidth ? { width: '100%' } : {}),
			...(fitContent ? { width: 'fit-content' } : {}),
			...(padding ? { padding } : {}),
			...(margin ? { margin } : {}),
			...(border ? { border } : {}),
			...style,
		};

		const handleLoadingState = useCallback(
			e => {
				if (e.type === 'click' || e.key === 'Enter') {
					const ref = e.target ?? e.srcElement;
					if (ref) {
						ref.innerText = loadingText;
						ref.classList.add('fetching');
					}
				}
			},
			[loadingText]
		);
		const renderButtonContent = () => {
			if (withChevron || withClose) {
				const position = withClose ? closePosition : chevronPosition;
				const icon = withClose ? (
					<Close
						wrapperClass={disabled ? [styles['button--disabled']] : null}
						label='Close'
						onClick={disabled ? null : onClose}
					/>
				) : (
					<Chevron color={chevronColor} direction={chevronDirection} />
				);
				return (
					<>
						{position === 'front' && icon}
						<span>{children || label}</span>
						{position != 'front' && icon}
					</>
				);
			}
			if (withPrice)
				return (
					<>
						{!showPriceWithLabel && <span data-add-to-cart-text />}
						{showPriceWithLabel && <div>{label}</div>}
						<div className={styles.priceContainer}>
							<span className={!!compareAtPrice ? styles.redPrice : styles.price} data-pricing={price}>
								{formatCurrency({ amount: price, currencyCode, locale: locale }, showCurr)}
							</span>
							{compareAtPrice && (
								<span className={styles.strikethroughPrice} data-pricing={compareAtPrice}>
									{formatCurrency({ amount: compareAtPrice, currencyCode, locale: locale }, showCurr)}
								</span>
							)}
						</div>
					</>
				);

			if (showSpinner) return <Loading className={styles.loading} removeDefaultStyling white={color !== 'white'} />;
			return children || label;
		};

		return href ? (
			<Link
				aria-label={label}
				href={href}
				type={type}
				disabled={disabled}
				data-remove-effects={removeEffects}
				data-href={href}
				tabIndex={1}
				role='link'
				rel={props?.target === '_blank' ? 'noreferrer noopener' : undefined}
				{...(onClick && {
					onClick: e => {
						loadingText && handleLoadingState(e);
						onClick(e);
					},
				})}
				{...(dataTags ? dataTags : {})}
				style={styleOverrides}
				className={buttonClasses}
				// TODO - merge changes from branch `polymorphic-button`, remove ts-ignore
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				ref={ref}
				{...props}
			>
				<span
					data-text-animate
					data-children={children && true}
					data-chevron={withChevron && true}
					data-with-price={withPrice && true}
					data-space-between={spaceBetween && true}
				>
					{renderButtonContent()}
				</span>
			</Link>
		) : (
			<button
				aria-label={label}
				type={type}
				disabled={disabled}
				data-remove-effects={removeEffects}
				data-href={href}
				role={href ? 'link' : 'button'}
				tabIndex={1}
				onClick={e => {
					loadingText && handleLoadingState(e);
					onClick && onClick(e);
				}}
				style={styleOverrides}
				className={buttonClasses}
				ref={ref as LegacyRef<HTMLButtonElement>}
				{...(dataTags ? dataTags : {})}
				{...props}
			>
				{icon && icon}
				<span
					data-text-animate
					data-children={children && true}
					data-chevron={withChevron && true}
					data-with-price={withPrice && true}
					data-space-between={spaceBetween && true}
				>
					{renderButtonContent()}
				</span>
			</button>
		);
	}
);

Button.displayName = 'Button';

export default Button;
