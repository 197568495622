const en = {
  // =============== A ===============
  "add": "add",
  "add-to-cart": "Add to Cart",
  "add-a-top-frame": "Add a Top Frame",
  "adding": "Adding",
  "add-to-cart-go-to-tops": "Add to Cart & Go to Tops",
  "all-time": "all-time",
  "accessories": "Accessories",
  "added": "Added",
  "account-card-list-items": ({ itemsNumber }: { itemsNumber: string }) => `+ ${itemsNumber} Items`,
  "account-card-list-item": ({ itemNumber }: { itemNumber: string }) => `+ ${itemNumber} Item`,
  // =============== B ===============
  "back": "Back",
  "build-purchase-edit-frame-save": "Please proceed through 'Build & Purchase' to make changes to the chosen Base Frame in your cart. Select 'Update Cart' to save your most recent edits!",
  "base-frames": "Base Frames",
  "back-to-my-orders": "Back to My Orders",
  // =============== C ===============
  "color": "Color",
  "collection": "collection",
  "collection-uppercase": "Collection",
  "collection-title": ({ collectionTitle }: { collectionTitle: string }) => `${collectionTitle} Collection`,
  "close": "close",
  "close-uppercase": "close",
  "contact-customer-service": "contact our customer service team",
  "contact-us": "Contact Us",
  "contact-us-button-tittle": "Opens ADA chatbot for immediate customer service",
  "check-readers-strength" : "Check your Readers Strength!",
  "choose-lens-type": "Choose Lens Type",
  "choose-subscription": "Choose Subscription",
  "choose-top-frames": "Choose Top Frames",
  "choose-additional-tops": "Choose Additional Tops",
  // =============== D ===============
  "download-invoice": "Download invoice",
  "design": "Design",
  // =============== E ===============
  "edit-bundle": "Edit Your Bundle",
	"edit-pair": "Edit Your Pair",
  "exit": "Exit",
  "eye-doctor-contact": "Eye doctor will be contacted",
  "entered-manually": "Entered in manually",
  "earn-more-points": "Earn More Points",
  "explore-all-tops": "Explore All Tops",
  "explore-favorites": "Explore Your Favorites",
  // ============================== Errors =============================================
  "error-loading-order-details-message": 'There was an error loading your order details or the order number does not exist. Please contact our Customer Service team if there was a mistake. Otherwise, click "Back to My Orders" to see your order history',
  "error-subscription-submission-upload": "There was an error with the submission upload. Please contact our Customer Service team.",
  "error-subscription-submission-file-type": "You have selected an invalid file type. Please upload a file with one of the following extensions:",
  "fetching-invoice": "Fetching Invoice...",
  // =============== F ===============
  "favorites": "favorites",
  // =============== G ===============
  "got-it": "Got It",
  "go-to-rewards": "Go to My Rewards",
  "grateful-phrase-PariCarse": "Thanks for adding PairCare!",
  "get-frames-faster": "Get your frames faster!",
  // =============== H ===============
  "how-redeem-points": "How Do I Redeem My Points?",
  // =============== I ===============
  "items-in-order": "Items in this order",
  "included": "Included",
  // =============== L ===============
  "last-action-error": "We're having trouble with your last action. Please try again later.",
  "last-3-months": "last 3 months",
  "last-6-months": "last 6 months",
  "loading": "Loading...",
  "loading-collections": "Loading Collections...",
  // =============== M ===============
  "make-a-selection": "Make a Selection",
  "manually-prescription": "Manually Enter Prescription",
  "method": "method",
  "my-tab-name": ({tabName}: { tabName: string }) => `My ${tabName}`,
  "my-collection-name": ({ collectionName }: { collectionName: string }) => `My ${collectionName}`,
  "my-favorites": "My Favorites",
  "my-collection": "My Collection",
  "my-orders": "My Orders",
  "my-rewards": "My Rewards",
  "my-subscriptions": "My Subscriptions",
  "my-empty-collection-title": "You haven't placed any orders yet!",
  "my-empty-collection-paragraph": "Once you do, your purchases will show up here.",
  // =============== N ===============
  "no-order-found": "No Order Found",
  "no-order-found-message": "We could not find this order in our system.",
  "no-orders-in-time-period": "No orders within this time period!",
  "no-orders-found": "You have no orders!",
  "no-favorites-heading": "You currently have no Favorites",
  "no-favorites-body": "Start exploring and save your favorite top frames now!",
  // =============== O ===============
  "open-minicart": "Open Minicart",
  "orders": "orders",
  "order-line-items": ({ orderItems }: { orderItems: string }) => `(${orderItems} items)`,
  "orders-placed": "orders placed",
  "order-placed": "order placed",
  "order-tracker": "Order tracker",
  "ordered-date": ({ orderedDate }: { orderedDate: string }) => `Ordered on ${orderedDate}`,
  "order-number-message": ({ orderNumber }: { orderNumber: string }) => `Order #${orderNumber}`,
  "ordered-previously": "Ordered Previously",
  "our-specialty": "Our Specialty",
  // =============== P ===============
  "past-year": "past year",
  "prescription-photo-uploaded": "Photo of prescription uploaded",
  "pair-points-reward": ({ pointsBalance }: { pointsBalance: string }) => `You have  ${pointsBalance} Pair Points`,
  "pair-points-until-reward": ({ pointsBalance, nextRewardPoints }: { pointsBalance: string, nextRewardPoints: string }) => `${pointsBalance}/${nextRewardPoints} Pair Points until your next reward`,
  "pair-care-upsell-state-available-line-1": "By applying PairCare to your order, you're taking the right steps to protect your Base Frame purchase.",
  "pair-care-upsell-state-available-line-2": "PairCare is a one-year warranty that covers Base Frames, lenses, and lens add-ons in the event your Pair is scratched or damaged within one year from delivery. PairCare does not include Top Frames and doesn't cover theft or loss of the item. For more information visit our FAQs.",
  "pair-care-upsell-state-ineligible-line-1": "By applying PairCare to your order, you're taking the right steps to protect your Base Frame purchase.",
  "pair-care-upsell-state-ineligible-line-2": "PairCare is a one-year warranty that covers Base Frames, lenses, and lens add-ons in the event your Pair is scratched or damaged within one year from delivery. PairCare does not include Top Frames and doesn't cover theft or loss of the item. For more information visit our FAQs.",
  "pair-care-upsell-state-purchased-line-1": "By applying PairCare to your order, you're taking the right steps to protect your Base Frame purchase.",
  "pair-care-upsell-state-purchased-line-2": "PairCare is a one-year warranty that covers Base Frames, lenses, and lens add-ons in the event your Pair is scratched or damaged within one year from delivery. PairCare does not include Top Frames and doesn't cover theft or loss of the item. For more information visit our FAQs.",
  "pair-care-upsell-state-pending-line-1": "Check your inbox for an email from us with payment details!",
  "pair-care-upsell-state-pending-line-2": "If you're unable to find the invoice in your email or need additional assistance, please",
  "pd-already-measured": "PD Already Measured",
  "pd-already-measured-message": "This order has the PD measured.",
	"pd-not-needed": "PD not Needed",
  "pd-not-needed-message": "Looks like this order has PD measured or doesn't need PD.",
  "plus-add": "+Add",
  "promotion-code": ({ code }: { code: string }) => `Promotion ${code}`,
  "pending": "Pending",
  "protect-base": "Protect Your Base Frame",
  "prescription-details-header":"Prescription Details",
  "prescription-details-paragraph":({ productTitle, variantTitle }: { productTitle: string; variantTitle: string }) => `for ${productTitle}  in ${variantTitle}`,
  // =============== R ===============
  "rewards": "rewards",
  "readers-strength-error": "Select the strength of your Readers to continue.",
  "recent-order": "Recent order",
  'reward-message-less-than-50': 'You are so close. 50 points earns you a $5 discount',
	'reward-message-50-99': 'You have $5 to use towards your next purchase',
	'reward-message-100-199': 'You have $10 to use towards your next purchase',
	'reward-message-200-299': 'You have $20 to use towards your next purchase',
	'reward-message-300-399': 'You have $30 to use towards your next purchase',
	'reward-message-400-499': 'You have $40 to use towards your next purchase',
	'reward-message-500-599': 'You have $50 to use towards your next purchase',
	'reward-message-600-999': 'You have +$60 to use towards your next purchase',
	'reward-message-1000+': 'You have +$100 to use towards your next purchase',
  // =============== S ===============
  "save-bundle-edits": "Make changes to your bundle size, Base Frame shape, and Tops selections. Select 'Update Bundle' to save your most recent edits!",
  "subscriptions": "subscriptions",
  "sent-exclamation" : "Sent!",
  "select-one-or-more": "Select One or More",
  "selected": "Selected",
  "shop-glasses": "Shop Glasses",
  "suggested-looks": "Looks We Think You’ll Love",
  "shop-tops": "Shop Tops you’ve had your eye on!",
  "status": "Status",
  "subtotal": "Subtotal",
  "select-collection": "Select Collection",
  "select-all-that-apply": "Select all that apply",
  "select": "Select",
  // =============== T ===============
  "tax": "Tax",
  "tax-included": "Tax Included",
  "tap-reward-to-learn": "Tap on the reward to learn more",
  "total": "Total",
  "total-discount": "Total Discount",
  "top-frames": "Top Frames",
  "tracking-number": "Tracking Number",
  "trouble-saving-info": "We're having trouble saving your information. Please continue to checkout, and we'll follow up via email after your order has been placed!",
  "tops-made=for-you": "These Tops were made for you!",
  // =============== U ===============
  "upload-prescription": "Upload Prescription",
  "update-cart": "Update Cart",
  // =============== V ===============
  "variant-unavailable": "Variant Unavailable",
  "variant-unavailable-message": "The variant is currently unavailable for your base frame.",
  "view-my-favorites": "View My Favorites",
  "view-order-details": "View Order Details",
  // =============== W ===============
  "welcome-back-text": ({ customerName }: { customerName: string }) =>  `Welcome back, ${customerName}!`,
  "whoops": "Whoops!",
  // =============== Y ===============
  "your-reward-level": "Your Reward Level",
  "your-friends-mail": "Your Friends' Emails (Separated by Commas)",
};

export default en;
