import { FC, forwardRef } from 'react';
import cn from 'classnames';
import { TypographyProps } from '@ts/components';
import styles from './Paragraph.module.scss';

/**
 * Paragraph
 *
 * @default
 * - Size: 1.4rem
 * - Weight: 400
 * - Height: 150%
 * - Family: var(--poppins)
 */
const Paragraph: FC<TypographyProps<HTMLParagraphElement>> = forwardRef(
	({ children, className, large = false, small = false, color, style, ...rest }, forwardedRef) => {
		const classes = cn(className, styles.default, {
			[styles.large]: large,
			[styles.small]: small,
		});
		return (
			<p ref={forwardedRef} className={classes} {...rest} style={{ color, ...style }}>
				{children}
			</p>
		);
	}
);

Paragraph.displayName = 'Paragraph';

export default Paragraph;
