import { useInfiniteHits } from 'react-instantsearch';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useRouter } from 'next/router';
import { normalizeProductTitle } from '@utils/normalizers';
import { BuildFlowCard, Card, MarketingCard, VerticalCard } from '@components';
import { PRODUCT_TYPES } from '@constants';
import { CardProps, NormalizedCollection, NormalizedProduct } from '@ts/index';
import {
	EMPTY_PRODUCT,
	EMPTY_VARIANT,
	FRAME_COLORS,
	getBaseName,
	getParsedHandleandColor,
	LOCALE_CODES,
	LOCALE_DICT,
	NEW_COLORS,
} from '@utils/index';

type HitProps = {
	locale?: string;
	hit: ReturnType<typeof useInfiniteHits>['hits'][number];
	position: number;
	type?: 'buildflow' | 'all-tops' | 'search' | 'bf-all-tops' | 'eyeglasses' | 'sunglasses';
	collection?: NormalizedCollection;
};

type HitFields = {
	[key: string]: string;
};

const Hit = ({ locale = LOCALE_CODES['US'], hit, position, type = 'all-tops', collection }: HitProps) => {
	const router = useRouter();
	const isSearch = type === 'search';
	const hideOnFigImagery = useFeatureIsOn('hide-on-fig-imagery');
	const isHideNewColors = useFeatureIsOn('is-hide-new-products');
	const isPDPExactPriceTest = useFeatureIsOn('is-pdp-exact-price-test');
	const isPlpCompareTestVariant2Active = typeof window !== 'undefined' && window.isPlpCompareTestVariant2Active;
	const isBaseFramePLP =
		router.asPath.split('?')[0].includes('eyeglasses') || router.asPath.split('?')[0].includes('sunglasses');
	const { demo } = router.query as { demo: string };

	const { handle, color } = getParsedHandleandColor(hit.handle as string);

	const mixedMaterialHandle = (hit.handle.match(/[\w-]*mixed-material/) ?? [])[0];
	const searchHandle = mixedMaterialHandle || handle;

	let productData = collection?.products.find(product => product.handle === searchHandle);

	if (!productData) {
		let price = hit.price;
		let compareAtPrice = hit.compare_at_price;
		if (locale !== LOCALE_CODES.US) {
			const currencyCode = LOCALE_DICT[locale].currencyCode.toLowerCase();
			const field = `market_pricing_${currencyCode}`;
			price = (hit[`${field}`] as HitFields)?.price || hit.price;
			compareAtPrice = (hit[`${field}`] as HitFields)?.compare_at_price || hit.compare_at_price;
		}

		productData = {
			...EMPTY_PRODUCT,
			...{
				tags: hit.tags,
				type: hit.product_type,
				id: hit.id,
				handle: hit.handle,
				name: normalizeProductTitle(hit.title, hit.product_type),
				variants: [
					{
						...EMPTY_VARIANT,
						...{
							id: `gid://shopify/ProductVariant/${hit.objectID}`, //! must be a Shopify GID !
							sku: hit.sku,
							handle: hit.handle,
							type: hit.product_type,
							name: normalizeProductTitle(hit.title, hit.product_type),
							option: hit.product_type === PRODUCT_TYPES.GIFT_CARD ? `${hit.option1}/${hit.option2}` : hit.option1,
							availableForSale: true,
							image: {
								url: hit.image,
							},
							price: {
								amount: price,
								currencyCode: LOCALE_DICT[locale].currencyCode,
							},
							compareAtPrice: compareAtPrice && {
								amount: compareAtPrice,
								currencyCode: LOCALE_DICT[locale].currencyCode,
							},
							product: {
								id: hit.id,
								handle: hit.handle,
								name: normalizeProductTitle(hit.title, hit.product_type),
								type: hit.product_type,
								tags: [],
							},
						},
					},
				],
			},
		} as NormalizedProduct;
	}

	if (isHideNewColors) {
		productData = {
			...productData,
			variants: productData.variants.filter(v => !NEW_COLORS.includes(v.option as FRAME_COLORS)),
		};
	}

	let cardProps: Omit<CardProps, 'children'> = {
		product: productData,
		variant: productData.variants[0],
		showTags: true,
		showCollectionLozenge: true,
		showDescription: true,
		secondaryAction: 'favorite',
		compact: true,
		searchPosition: position,
		dataTags: {
			button: { [isSearch ? 'data-add-to-cart-from-search' : 'data-add-to-cart-from-plp']: true },
			zoom: {},
			favorite: { [isSearch ? 'data-add-favorite-from-search' : 'data-add-favorite-from-plp']: true },
		},
	};

	if (productData.type === PRODUCT_TYPES.ACCESSORY) {
		cardProps = { ...cardProps, aspectRatio: '2/1' };
	}

	if (productData?.type?.includes(PRODUCT_TYPES.BASE_FRAME)) {
		cardProps = { ...cardProps, aspectRatio: '1/1', primaryAction: 'view' };
	}

	// NOTE: Fixes aspect ratio for Search Cards
	if (type === 'all-tops' || isSearch) cardProps.aspectRatio = '4/3';

	if (type === 'eyeglasses' || type === 'sunglasses')
		return position === 7 && isPlpCompareTestVariant2Active ? (
			<MarketingCard />
		) : (
			<VerticalCard
				buttonGroupType='stacked'
				key={productData.handle}
				parentCollectionHandle={demo}
				primaryAction='view'
				product={productData}
				secondaryAction={type == 'sunglasses' ? 'none' : 'vto'}
				showLensController={type == 'sunglasses'}
				showAction
				showVariantControls
				variant={productData.variants.find(variant => variant.handle === hit.handle)}
				hoverable={!hideOnFigImagery}
				label={`Design Your ${getBaseName(productData.name, false)}`}
				supplemental={' '}
				useExactPrice={isPDPExactPriceTest && isBaseFramePLP}
				initialColor={color}
				initialLens={hit?.option3.includes('Sun') ? hit.option3.replace('Sun - ', '') : undefined}
			/>
		);

	return type == 'buildflow' || type === 'bf-all-tops' ? (
		<BuildFlowCard top={cardProps.variant} />
	) : (
		<Card {...cardProps} pageType={type} />
	);
};

export default Hit;
