/* eslint-disable max-lines */
import { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { useRouter } from 'next/router';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import {
	ALSO_WORTH_CHECKOUT_COPY,
	BADGE_TYPES,
	BASE_FRAME_NAMES,
	LOCALE_DICT,
	MEDIUM_LARGE_WIDTH,
	SHOP_PAGE_URL,
	SHOPIFY_CHECKOUT_URL,
} from '@constants';
import variables from '@styles/export.module.scss';
import {
	BaseFrameBundle,
	Button,
	BuyBox,
	Caption,
	CartHeader,
	CartReviews,
	Checklist,
	Container,
	DiscountBanner,
	Divider,
	EmptyCart,
	Flex,
	Footer,
	FreeShippingTracker,
	Heading,
	HorizontalCard,
	LensUpsellSection,
	LineItemCard,
	Loading,
	SidebarLayout,
	Spacer,
	Subtotal,
	TopFrameRecommendation,
	TypographyButton,
} from '@components';
import { useDebounce, useHasMounted, useIsMobile, useLocalStorage } from '@utils/hooks';
import { useCart, useCustomer, useProductQuery } from '@services/shopify';
import { trackCartView } from '@services/analytics/trackers';
import { useSiteSettings } from '@services/contentful';
import { useCartContext } from '@context';
import fetchMultipassApi from '@services/shopify/fetch-multipass-api';
import { getCartBreakdown, getChecklist, getThreshold, getTopContainerClass, hasDuplicatedKeys } from '../cart-utils';
import styles from './Minicart.module.scss';

const Minicart = ({ forceMobileStyle = false, footer = null, isMinicart = false }) => {
	const [checkoutText, setCheckoutText] = useState('Checkout');
	const [activeFrame] = useLocalStorage<(typeof BASE_FRAME_NAMES)[number]>('activeFrame', 'Larkin');
	const { locale, push } = useRouter();
	const countryCode = LOCALE_DICT[locale].countryCode;
	const { data: cart, isLoading: isCartLoading } = useCart();
	const { data: siteSettings, isLoading: isSiteSettingsloading } = useSiteSettings();
	const { isCartMutating, isUploadingRx, handleCartRemove, applySubscriptionDiscount } = useCartContext();
	const isUS = countryCode === 'US';
	const isUpsellsInCheckout = useFeatureIsOn('is-upsells-in-checkout') && isUS;
	const { data: customer } = useCustomer();
	const { data: pairCare, isLoading: isPairCareLoading } = useProductQuery(
		'pair-care',
		{},
		{ queryRefreshVars: [locale], locale }
	);
	const { data: topFrameCase } = useProductQuery('top-frame-case', { country: countryCode }, { queryRefreshVars: [locale] });
	const { data: cleaningKit } = useProductQuery('cleaning-kit', { country: countryCode }, { queryRefreshVars: [locale] });
	const { data: accessoriesBundle } = useProductQuery(
		'accessories-bundle',
		{ country: countryCode },
		{ queryRefreshVars: [locale] }
	);

	const anyAsyncLoading = isSiteSettingsloading || isCartLoading || isPairCareLoading;
	const isMobile = useIsMobile({ maxWidth: MEDIUM_LARGE_WIDTH });
	const isMounted = useHasMounted();
	const debouncedTrackCartView = useDebounce(trackCartView);
	const threshold = getThreshold(locale, siteSettings);
	const isTrackingFreeShipping = !!threshold && !isSiteSettingsloading;
	const isKeepShoppingTestActive = useFeatureIsOn('is-cart-cta-test');
	const isCartUsability = useFeatureIsOn('is-cart-usability') && locale === 'en-US';
	const isBfroDiscount = useFeatureIsOn('is-bfro-discount');
	const isV2Subscription = useFeatureIsOn('is-v2-subscription-test');
	const accessories = [accessoriesBundle, topFrameCase, cleaningKit].filter(
		accessory => !!accessory && !cart?.lines.some(line => line.variant.product.name === accessory.name)
	);
	const router = useRouter();
	const { topFrames, bundles, others, customBundles, subscriptions } = getCartBreakdown(cart, isV2Subscription);
	const hasBundleOrBaseTops = bundles?.some(bundle => !!bundle?.tops || !!bundle?.base) && !isUpsellsInCheckout;
	const baseBundles = bundles.filter(bundle => !!bundle?.base);
	const isCheckoutDisabled = isCartMutating || isUploadingRx;

	const onClickCheckout = async () => {
		setCheckoutText('Loading');
		if (hasDuplicatedKeys(cart)) {
			console.error(`Cart with id ${cart.id} has duplicated keys`, cart);
		}

		if (!customer) {
			push(cart?.checkoutUrl.replace(SHOPIFY_CHECKOUT_URL, SHOP_PAGE_URL));
			return;
		}

		await fetchMultipassApi(cart, customer, push);
	};
	const ButtonContainer = ({ children }: { children: React.ReactNode }) =>
		isCartUsability ? (
			<Flex align='center' gap={3}>
				{children}
			</Flex>
		) : (
			<>{children}</>
		);
	const cta = (
		<>
			{isCartUsability ? (
				<BuyBox
					isMinicart={isMinicart}
					isMobile={isMobile}
					subtotal={cart?.subtotal}
					totalQuantity={cart?.totalQuantity}
				/>
			) : (
				<Subtotal subtotal={cart?.subtotal} />
			)}
			<ButtonContainer>
				{isCartUsability && isMinicart && (
					<Button color='white' fullWidth label='View Cart' href='/cart' data-view-cart />
				)}
				<Button
					onClick={onClickCheckout}
					data-checkout
					fullWidth
					label={checkoutText}
					disabled={!cart?.lines.length || isCheckoutDisabled}
					style={{
						pointerEvents: isCheckoutDisabled ? 'none' : 'auto',
						cursor: isCheckoutDisabled ? 'not-allowed' : 'pointer',
						height: '4.8rem',
					}}
				/>
			</ButtonContainer>
			{isKeepShoppingTestActive && !isMinicart && (
				<>
					<Spacer size={'0.8rem'} />
					<Button href='/' label='Keep Shopping' color='white' fullWidth data-keep-shopping />
				</>
			)}
		</>
	);

	const removeAllCustomBundle = (key: string) => {
		const ids = customBundles[key].map(({ top }) => top.id);
		handleCartRemove(ids);
	};

	useEffect(() => {
		if (cart) debouncedTrackCartView(cart);
	}, []);

	useEffect(() => {
		if (checkoutText === 'Loading') {
			setTimeout(() => {
				setCheckoutText('Checkout');
			}, 1500);
		}
	}, [checkoutText]);

	if (anyAsyncLoading || !isMounted) return <Loading />;

	const CartHeaderComponents = () => {
		const Subtotal = <CartHeader itemCount={cart?.totalQuantity ?? 0} subtotal={cart?.subtotal} />;
		const Discount = isBfroDiscount && <DiscountBanner margin='mobile' />;
		const Shipping = isTrackingFreeShipping && (
			<FreeShippingTracker
				locale={locale}
				freeShippingThreshold={getThreshold(locale, siteSettings)}
				cartTotal={cart?.subtotal}
			/>
		);
		const components = !isCartUsability ? [Subtotal, Discount, Shipping] : [Shipping, Subtotal, Discount];

		return components;
	};
	return (
		<>
			{forceMobileStyle && <SidebarLayout.Header inDrawer={forceMobileStyle} />}
			<SidebarLayout.Root
				forceMobileStyle={forceMobileStyle}
				cartStyle
				isMinicart={isMinicart}
				isCartUsability={isCartUsability}
			>
				<SidebarLayout.Content
					className={styles.content}
					forceMobileStyle={forceMobileStyle}
					isMinicart={isMinicart}
					isMobile={isMobile}
					cartStyle
					isCartUsability={isCartUsability}
				>
					<Flex
						className={forceMobileStyle ? styles.containerInDrawer : styles.container}
						column
						center
						fullWidth
						gap={4}
					>
						<CartHeaderComponents />
						{!cart?.lines.length && <EmptyCart />}
						{baseBundles.map((bundle, index) =>
							bundle?.base ? (
								<>
									<BaseFrameBundle
										isLoading={anyAsyncLoading}
										pairCare={pairCare}
										key={bundle.key}
										bundle={bundle}
										cartId={cart?.id}
										forceMobileStyle={
											isCartUsability ? isMinicart || isMobile || forceMobileStyle : forceMobileStyle
										}
										tags={applySubscriptionDiscount ? [BADGE_TYPES.MEMBERS_DISCOUNT] : []}
									/>
									{isCartUsability && (
										<LensUpsellSection
											isLoading={anyAsyncLoading}
											key={bundle.key + cart?.id}
											bundle={bundle}
										/>
									)}
									{isCartUsability && index < baseBundles.length - 1 && (
										<Divider margin={'2.4rem'} color={variables.gray2} alignment='horizontal' />
									)}
								</>
							) : null
						)}
						{!isCartUsability && subscriptions.length > 0 && (
							<Flex column gap={4} maxWidth className={getTopContainerClass(forceMobileStyle, styles)}>
								<Caption className={styles.bundleTitle}>{`My Subscription(s)`}</Caption>
								{subscriptions.map(({ optimistic, top }) => (
									<LineItemCard
										key={`line-${top?.id ?? nanoid()}`}
										data={top}
										dataSource={'shopify'}
										optimistic={optimistic}
										dataTags={{
											button: {},
											zoom: {
												'data-tops-zoom': 'cart',
											},
										}}
									/>
								))}
							</Flex>
						)}
						{topFrames.length > 0 && (
							<Flex column gap={4} maxWidth className={getTopContainerClass(forceMobileStyle, styles)}>
								<Caption className={isCartUsability ? styles.topFrameTitle : styles.bundleTitle}>
									My Top Frames
								</Caption>
								{topFrames.map(({ optimistic, top }) => (
									<LineItemCard
										isMinicart={isMinicart}
										key={`line-${top?.id ?? nanoid()}`}
										data={top}
										dataSource={'shopify'}
										optimistic={optimistic}
										showTags
										customTags={applySubscriptionDiscount ? [BADGE_TYPES.MEMBERS_DISCOUNT] : []}
										dataTags={{
											button: {},
											zoom: {
												'data-tops-zoom': 'cart',
											},
										}}
										forceDefault={false}
									/>
								))}
							</Flex>
						)}
						{isCartUsability && others.length > 0 && (
							<Flex
								column
								gap={3}
								maxWidth
								className={getTopContainerClass(forceMobileStyle, styles)}
								data-testid='accessories-items'
							>
								<Heading tag='h6' className={styles.cartUsabilityAccessories}>
									Accessories
								</Heading>
								{others.map(({ item, optimistic }) => (
									<LineItemCard
										key={`line-${item?.id ?? nanoid()}`}
										data={item}
										dataSource={'shopify'}
										optimistic={optimistic}
										large={isCartUsability}
										isMinicart={isMinicart}
									/>
								))}
							</Flex>
						)}
						{customBundles &&
							Object.keys(customBundles).map(key => {
								return (
									<Flex
										key={key}
										column
										gap={4}
										maxWidth
										className={getTopContainerClass(forceMobileStyle, styles)}
									>
										<Flex justify='between'>
											<Caption className={styles.bundleTitle}>My Bundle</Caption>
											<Flex justify='end' gap={4}>
												<Button
													size='small'
													linkStyle
													onClick={() => {
														router.push({
															pathname: '/build-your-own-bundle',
															query: {
																edit: true,
																bundle: key,
															},
														});
													}}
													dataTags={{ 'data-cart-edit': 'BYOB' }}
												>
													Edit
												</Button>
												<TypographyButton small style={{ color: variables.gray4 }}>
													|
												</TypographyButton>
												<Button
													size='small'
													linkStyle
													onClick={() => removeAllCustomBundle(key)}
													dataTags={{
														'data-remove': `BYOB-${customBundles[key].length}Top`,
														'data-type-remove': 'BYOB',
													}}
												>
													Remove
												</Button>
											</Flex>
										</Flex>
										{customBundles[key].map(({ optimistic, top }) => {
											return (
												<LineItemCard
													key={`line-${top?.id ?? nanoid()}`}
													data={top}
													dataSource={'shopify'}
													noInteraction
													forceDefault
													optimistic={optimistic}
													dataTags={{
														button: {},
														zoom: {
															'data-tops-zoom': 'cart',
														},
													}}
												/>
											);
										})}
									</Flex>
								);
							})}
						{isCartUsability && subscriptions.length > 0 && (
							<Flex column gap={4} maxWidth className={getTopContainerClass(forceMobileStyle, styles)}>
								<Caption className={styles.bundleTitle}>My Subscription</Caption>
								{subscriptions.map(({ optimistic, top }) => (
									<LineItemCard
										key={`line-${top?.id ?? nanoid()}`}
										data={top}
										dataSource={'shopify'}
										optimistic={optimistic}
										forceDefault
										dataTags={{
											button: {},
											zoom: {
												'data-tops-zoom': 'cart',
											},
										}}
									/>
								))}
							</Flex>
						)}
						{!isCartUsability && others.length > 0 && (
							<Flex
								column
								gap={4}
								maxWidth
								className={getTopContainerClass(forceMobileStyle, styles)}
								data-testid='other-items'
							>
								<Caption className={styles.bundleTitle}>Other Items</Caption>
								{others.map(({ item, optimistic }) => {
									return (
										<LineItemCard
											key={`line-${item?.id ?? nanoid()}`}
											data={item}
											dataSource={'shopify'}
											optimistic={optimistic}
										/>
									);
								})}
							</Flex>
						)}
						{!isCartUsability && hasBundleOrBaseTops && !isMobile && !isMinicart && (
							<TopFrameRecommendation activeFrame={activeFrame} bundles={bundles} isMinicart={forceMobileStyle} />
						)}
					</Flex>
					{!!footer && !isMobile && (
						<Flex fullWidth className={styles['footer']}>
							<Footer footer={footer} page='cart' />
						</Flex>
					)}
				</SidebarLayout.Content>
				{!isCartUsability && hasBundleOrBaseTops && (isMobile || isMinicart) && (
					<TopFrameRecommendation activeFrame={activeFrame} bundles={bundles} isMinicart={forceMobileStyle} />
				)}
				<SidebarLayout.Sidebar
					isMinicart={isMinicart}
					isCartUsability={isCartUsability}
					forceMobileStyle={forceMobileStyle}
					isMobile={isMobile}
					cartStyle
				>
					{!isUpsellsInCheckout && accessories.length > 0 && (
						<Container
							backgroundColor='white'
							pad={4}
							borderRadius={4}
							className={styles['upsellContainer']}
							data-testid='accessories-upsell'
						>
							<Caption
								className={
									cart?.lines.length || forceMobileStyle ? styles['upsellTitle'] : styles['upsellTitle--empty']
								}
							>
								Complete your order with our accessories
							</Caption>
							<Flex column gap={3} align='center'>
								{accessories.map(u => {
									if (!u || cart?.lines.some(line => line.variant.product.name === u.name)) return null;

									return (
										<HorizontalCard
											key={u?.handle}
											product={u}
											variant={u?.variants[0]}
											alreadyInCart={cart?.lines.some(line => line.variant.product.name === u.name)}
											openMinicartOnPurchase={false}
											showTags={false}
											description={ALSO_WORTH_CHECKOUT_COPY[u?.handle] ?? u?.description}
											dataTags={{
												button: u?.handle ? { 'data-accessory-added': u?.handle } : {},
												zoom: {},
												favorite: {},
											}}
											isMinicart
										/>
									);
								})}
							</Flex>
						</Container>
					)}
					{!!cart?.lines.length && (
						<Container className={styles['checklistPadded']} backgroundColor='white'>
							<Checklist
								title='With your purchase: '
								backgroundColor='transparent'
								list={getChecklist(cart, locale, siteSettings)}
							/>
						</Container>
					)}
					{!forceMobileStyle && !isMobile && (
						<SidebarLayout.CTA isCartUsability={isCartUsability} isMinicart={isMinicart}>
							{cta}
						</SidebarLayout.CTA>
					)}
					{isCartUsability && <CartReviews />}
				</SidebarLayout.Sidebar>
			</SidebarLayout.Root>
			{!!footer && isMobile && (
				<Flex fullWidth className={styles['footer']}>
					<Footer footer={footer} page='cart' />
				</Flex>
			)}
			{(forceMobileStyle || isMobile) && (
				<SidebarLayout.CTA forceMobileStyle isCartUsability={isCartUsability} isMinicart={isMinicart}>
					{cta}
				</SidebarLayout.CTA>
			)}
		</>
	);
};

export default Minicart;
