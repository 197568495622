import React, { useState } from 'react';
import cn from 'classnames';
import { Button, Flex, Paragraph, PdToolModal, RadioGroup, Select, Tooltip } from '@components';
import { useCartContext } from '@context';
import styles from './CartPD.module.scss';

type CartPdProps = {
	callback: (pd: number) => void;
	pdMeasurement?: string;
};

const CartPD = ({ callback, pdMeasurement }: CartPdProps) => {
	const [hasMeasuredPd, setHasMeasuredPd] = useState(false);
	const [pDModalOpen, setPDModalOpen] = useState(false);
	const [pdToolInit, setPdToolInit] = useState(false);
	const [pdToolHasError, setPdToolHasError] = useState(false);
	const [hasScriptLoaded, setHasScriptLoaded] = useState(false);
	const [selectedDefault, setSelectedDefault] = useState<'men' | 'women'>();
	const { isCartMutating, isUploadingRx } = useCartContext();
	const isCartDisabled = isCartMutating || isUploadingRx;
	const pdValues = Array.from({ length: (75 - 45) / 0.5 + 1 }, (_, i) => {
		const num = 45 + i * 0.5;
		return Number.isInteger(num) ? num.toString() : num.toFixed(1);
	});

	const measureButton = (
		<Button
			extraClasses={styles['measure-button']}
			disabled={!(pdToolInit || pdToolHasError) || hasMeasuredPd}
			color='blue'
			onClick={() => setPDModalOpen(true)}
		>
			Measure
		</Button>
	);

	return (
		<Flex className={styles.container} column gap={3}>
			<Flex gap={2}>
				<Paragraph className={styles.bundleTitle}>Do You Have Your PD?</Paragraph>
				<Tooltip message='Distance between your two pupils and needed before we can make your Pair!' />
			</Flex>
			<Flex className={styles.form} gap={3}>
				<Select
					shouldUseRefresh
					className={styles.select}
					withChevron={!pdMeasurement}
					buttonClassName={cn(styles['select-button'], { [styles['placeholder']]: !pdMeasurement })}
					placeholder={pdMeasurement ?? 'PD Value'}
					prevSelection={pdMeasurement}
					values={pdValues}
					scrollTo={pdMeasurement ?? '61'}
					buttonProps={{
						withClose: !!pdMeasurement,
						disabled: isCartDisabled,
						onClose: () => {
							callback(null);
							setSelectedDefault(null);
						},
					}}
					handler={v => {
						callback(Number(v));
					}}
				/>
				{hasMeasuredPd ? (
					<Tooltip message='Measurement tool can only be used once.'>{measureButton}</Tooltip>
				) : (
					measureButton
				)}
				<PdToolModal
					open={pDModalOpen}
					setOpen={setPDModalOpen}
					setPdToolInit={setPdToolInit}
					setPdToolHasError={setPdToolHasError}
					hasScriptLoaded={hasScriptLoaded}
					onScriptLoaded={() => setHasScriptLoaded(true)}
					pdValueHandler={(pd: number) => {
						setHasMeasuredPd(true);
						setPDModalOpen(false);
						callback(pd);
					}}
					strategy='onReady'
				/>
			</Flex>
			<RadioGroup className={styles.defaults} value={selectedDefault}>
				<RadioGroup.Option
					className={styles['default-option']}
					option='men'
					callback={() => {
						setSelectedDefault('men');
						callback(64);
					}}
				>
					Use Men&apos;s Default
				</RadioGroup.Option>
				<RadioGroup.Option
					className={styles['default-option']}
					option='women'
					callback={() => {
						setSelectedDefault('women');
						callback(62);
					}}
				>
					Use Women&apos;s Default
				</RadioGroup.Option>
			</RadioGroup>
		</Flex>
	);
};

export default CartPD;
