import { useCallback } from 'react';
import { useCartContext } from '@context';
import { CartNavButton, Drawer } from '@components';
import { useTranslation } from '@utils/index';

const MinicartTrigger = ({ closeSearchRef = null, openMinicartRef = null }) => {
	const { isMicrocartTest } = useCartContext();
	const { translator } = useTranslation();

	const handleMinicartClick = useCallback(() => {
		if (closeSearchRef?.current && openMinicartRef?.current) closeSearchRef.current.click();

		if (openMinicartRef?.current) openMinicartRef.current.click();
	}, [closeSearchRef, openMinicartRef]);

	const cartNavButtonProps = isMicrocartTest
		? {
				href: '/cart',
				['data-test']: 'microcart',
			}
		: {
				handleMinicartClick,
				title: translator('open-minicart'),
				['data-test']: 'minicart',
			};

	return (
		<Drawer.Trigger asChild>
			<>
				<CartNavButton id='minicart' {...cartNavButtonProps} />
			</>
		</Drawer.Trigger>
	);
};

export default MinicartTrigger;
