import { ReactNode, useCallback } from 'react';
import { useRouter } from 'next/router';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import {
	Caption,
	CompareAtPrice,
	Detail,
	Divider,
	Flex,
	FrameSizingButton,
	Heading,
	LabelText,
	Paragraph,
	Spacer,
	Title,
} from '@components';
import { generateVariantCaption, PRODUCT_TYPES } from '@utils/index';
import { Image } from '@ts/shopify-storefront-api';
import variables from '@styles/export.module.scss';
import { CardProps } from '@ts/index';

type CardCopyProps = {
	showAction?: boolean;
	showDescription?: boolean;
	description?: string | ReactNode;
	supplementalCopy?: string;
	compact: boolean;
	useExactPrice?: boolean;
};

const Copy = ({
	description: controlledDescription,
	showDescription = true,
	showAction = false,
	supplementalCopy,
	compact,
	product,
	variant,
	quantity,
	containerType: type,
	useExactPrice = false,
}: CardCopyProps & Omit<CardProps, 'children'>) => {
	const { locale } = useRouter();
	const isCartUsability = useFeatureIsOn('is-cart-usability') && locale === 'en-US';
	const description = controlledDescription || generateVariantCaption(variant, product.type, isCartUsability);
	const supplemental = supplementalCopy || variant.metafields.shipmentInfo;
	const primaryImage: Image = variant.image;

	const ProductName = useCallback(
		({ children }) => {
			if (type === 'line-item' || type === 'build-flow') {
				return <LabelText style={{ lineHeight: 1, paddingTop: '0.5rem' }}>{children}</LabelText>;
			}
			if (compact) {
				return <Title>{children}</Title>;
			}
			return (
				<Heading
					tag='h6'
					removeDefaultMargin
					style={{
						flexShrink: '0',
						flexBasis: !showAction ? '100%' : 'auto',
					}}
				>
					{children}
				</Heading>
			);
		},
		[compact, type, showAction]
	);

	const Description = useCallback(
		({ children }) => {
			if (type === 'line-item') {
				return <Detail>{children}</Detail>;
			}
			return <Paragraph>{children}</Paragraph>;
		},
		[type]
	);

	const Action = useCallback(() => {
		return product.type.includes(PRODUCT_TYPES.BASE_FRAME) && product.handle.length > 0 ? (
			<FrameSizingButton frameSizings={product.metafields.frameSizings} name={product.name} primaryImage={primaryImage} />
		) : null;
	}, [product.type, product?.handle?.length, product.metafields, product.name, primaryImage]);

	const alignTitle = isCartUsability && !description;

	return (
		<Flex fullWidth column align='start' {...(alignTitle && { justify: 'center' })}>
			<Flex fullWidth justify='between'>
				<Flex align='center'>
					<ProductName>{`${product.name} ${quantity > 1 ? `x ${quantity}` : ''}`}</ProductName>
					{showAction && (
						<Flex align='center'>
							<Spacer size={'0.8rem'} />
							<Divider alignment='vertical' color={variables.gray2} margin={'0'} />
							<Spacer size={'0.4rem'} />
							<Action />
						</Flex>
					)}
				</Flex>
				{useExactPrice && product.type.includes(PRODUCT_TYPES.BASE_FRAME) && (
					<CompareAtPrice price={variant?.price} compareAtPrice={variant?.compareAtPrice} />
				)}
			</Flex>
			{!alignTitle && <Spacer size={'0.2rem'} />}
			{showDescription && ((description as string).length > 0 || !!(description as ReactNode)) && (
				<Description>{description}</Description>
			)}
			{supplemental && (
				<>
					<Spacer size={'0.4rem'} />
					<Caption small color={variables.orange2}>
						{supplemental}
					</Caption>
				</>
			)}

			{!((type === 'horizontal' && compact) || type === 'line-item') && (
				<Spacer size={compact || type === 'horizontal' ? '0.8rem' : '1.6rem'} />
			)}
		</Flex>
	);
};

export default Copy;
