import { Caption, Flex, Paragraph, Star } from '@components';
import variables from '@styles/export.module.scss';
import styles from './CartReview.module.scss';
interface CartReviewProps {
	description: string;
	name: string;
	location: string;
	rating: number;
}
function CartReview({ description, name, location, rating }: CartReviewProps) {
	return (
		<Flex column gap={4} className={styles.container}>
			<Paragraph>{`“${description}”`}</Paragraph>
			<Flex gap={2}>{Array(rating).fill(<Star color={variables.orange1} stroke={0} />)}</Flex>
			<Flex gap={2}>
				<Caption small color={variables.blue2}>
					{name}
				</Caption>
				<Caption small color={variables.gray4}>
					{`from ${location}`}
				</Caption>
			</Flex>
		</Flex>
	);
}

export default CartReview;
