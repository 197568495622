import { useRouter } from 'next/router';
import { Dict } from '@ts/general';
import { translate } from '@utils/locales';

export type Translate = () => { translator: (key: string, args?: Dict) => string; locale?: string };

const useTranslation: Translate = () => {
	const { locale } = useRouter();

	/**
	 * Translates a key using the current locale.
	 * @param key - The key to translate.
	 * @param args - Optional arguments for dynamic translations.
	 * @returns The translated string or the key if not found.
	 */
	const translateFunction = (key: string, args: Dict = {}) => {
		return translate(key, locale, args);
	};

	return { translator: translateFunction, locale };
};

export default useTranslation;
