import { useRouter } from 'next/router';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Flex } from '@components';
import { SubtotalHeader } from '@components';
import styles from './CartHeader.module.scss';

type CartHeaderProps = {
	subtotal: number;
	itemCount: number;
};

const CartHeader = ({ subtotal, itemCount }: CartHeaderProps) => {
	const { locale } = useRouter();
	const isCartUsability = useFeatureIsOn('is-cart-usability') && locale === 'en-US';

	return (
		<Flex
			className={isCartUsability ? styles.CartUsabilityContainer : styles.defaultContainer}
			maxWidth
			justify='between'
			align='center'
		>
			<SubtotalHeader subtotal={subtotal} itemCount={itemCount} />
		</Flex>
	);
};

export default CartHeader;
