import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

const VideoIcon = ({ width = 20, height = 21 }) => {
	return (
		<AccessibleIcon.Root label={'Video Icon'}>
			<svg width={width} height={height} viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M13.125 16.75H2.5C2.16848 16.75 1.85054 16.6183 1.61612 16.3839C1.3817 16.1495 1.25 15.8315 1.25 15.5V5.5C1.25 5.16848 1.3817 4.85054 1.61612 4.61612C1.85054 4.3817 2.16848 4.25 2.5 4.25H13.125C13.4565 4.25 13.7745 4.3817 14.0089 4.61612C14.2433 4.85054 14.375 5.16848 14.375 5.5V8.0375L17.7625 5.61875C17.8558 5.55233 17.9655 5.51282 18.0797 5.50452C18.1939 5.49623 18.3082 5.51947 18.4101 5.57171C18.512 5.62395 18.5976 5.70318 18.6576 5.80075C18.7175 5.89833 18.7495 6.01049 18.75 6.125V14.875C18.7495 14.9895 18.7175 15.1017 18.6576 15.1992C18.5976 15.2968 18.512 15.3761 18.4101 15.4283C18.3082 15.4805 18.1939 15.5038 18.0797 15.4955C17.9655 15.4872 17.8558 15.4477 17.7625 15.3813L14.375 12.9625V15.5C14.375 15.8315 14.2433 16.1495 14.0089 16.3839C13.7745 16.6183 13.4565 16.75 13.125 16.75ZM2.5 5.5V15.5H13.125V11.75C13.1255 11.6355 13.1575 11.5233 13.2174 11.4258C13.2774 11.3282 13.363 11.2489 13.4649 11.1967C13.5668 11.1445 13.6811 11.1212 13.7953 11.1295C13.9095 11.1378 14.0192 11.1773 14.1125 11.2438L17.5 13.6625V7.3375L14.1125 9.75625C14.0192 9.82267 13.9095 9.86218 13.7953 9.87048C13.6811 9.87877 13.5668 9.85553 13.4649 9.80329C13.363 9.75105 13.2774 9.67182 13.2174 9.57425C13.1575 9.47667 13.1255 9.36451 13.125 9.25V5.5H2.5Z'
					fill='#080593'
				/>
			</svg>
		</AccessibleIcon.Root>
	);
};

export default VideoIcon;
