/* eslint-disable max-lines */
import { useEffect, useRef, useState, Dispatch } from 'react';
import { useRouter } from 'next/router';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import cn from 'classnames';
import { Navigation, Pagination, Zoom } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { PaginationOptions } from 'swiper/types';
import { NormalizedVariant, NormalizedProduct } from '@ts/product';
import { DEFAULT_SWIPER, MEDIUM_WIDTH, PRODUCT_TYPES } from '@constants';
import { ConditionalLink, Img, Modal, SaveTag, Flex, Button, VideoIcon } from '@components';
import { useIsMobile } from '@utils/hooks';
import { BaseFrameStateDispatch } from '@utils/hooks/useBaseFrame';
import styles from './RichMedia.module.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/zoom';

type RichMediaProps = {
	containerType: 'vertical' | 'horizontal' | 'line-item' | 'build-flow' | 'large';
	href?: string;
	src: string;
	title: string;
	aspectRatio: string;
	variant?: NormalizedVariant;
	ImgClickHandler?: () => void;
	pageType?: string;
	isModalPresent?: boolean;
	hoverSrc?: string;
	className?: string;
	hoverable?: boolean;
	isMinicart?: boolean;
	forceDefault?: boolean;
	secondaryAction?: 'none' | 'favorite' | 'zoom' | 'vto' | 'tooltip' | 'custom';
	dispatch?: Dispatch<BaseFrameStateDispatch>;
	product?: NormalizedProduct;
};

type imageProps = {
	src: string;
	style?: React.CSSProperties;
	alt?: string;
};

const DEFAULT_SWIPER_PROPS = {
	...DEFAULT_SWIPER,
	pagination: {
		clickable: true,
		type: 'bullets',
		bulletElement: 'button',
		el: '.swiper-pagination',
		bulletClass: styles['swiper-pagination-bullet'],
		bulletActiveClass: styles['swiper-pagination-bullet-active'],
		lockClass: styles['swiper-pagination-lock'],
	} as PaginationOptions,
};

const RichMedia = ({
	containerType,
	href,
	src,
	hoverSrc,
	aspectRatio,
	title,
	variant,
	ImgClickHandler,
	pageType,
	isModalPresent,
	className,
	isMinicart = false,
	forceDefault = true,
	secondaryAction,
	dispatch,
	product,
}: RichMediaProps) => {
	const { locale } = useRouter();
	const [isHovered, setIsHovered] = useState(false);
	const isMobile = useIsMobile({ maxWidth: MEDIUM_WIDTH });
	const navigationPrevRef = useRef(null);
	const navigationNextRef = useRef(null);
	const [swiperKey, setSwiperKey] = useState(0);
	const isCartUsability = useFeatureIsOn('is-cart-usability') && locale === 'en-US';
	const isVTOonCarouselImage = useFeatureIsOn('is-vto-on-carousel-image');

	const navigationRefs = {
		prevEl: navigationPrevRef.current,
		nextEl: navigationNextRef.current,
	};

	useEffect(() => {
		if (hoverSrc) {
			const img = new Image();
			img.src = hoverSrc;
		}
	}, [hoverSrc]);

	useEffect(() => {
		const handleVisibilityChange = () => {
			if (document.visibilityState === 'visible') {
				setSwiperKey(prevKey => prevKey + 1);
			}
		};

		document.addEventListener('visibilitychange', handleVisibilityChange);

		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	}, []);

	const handleMouseEnter = () => {
		if (!hoverSrc || isMobile) return;
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		if (!hoverSrc || isMobile) return;
		setIsHovered(false);
	};

	const isVerticalCard = containerType === 'vertical';

	const containerClasses = cn(styles.richMedia, className, {
		[styles.richMediaLineItem]: containerType === 'line-item',
		[styles.richMediaHorizontal]: containerType === 'horizontal',
		[styles.richMediaVertical]: isVerticalCard,
		[styles.richMediaBuildFlow]: containerType === 'build-flow',
		[styles.richMediaWithBigImage]: containerType === 'line-item' && isCartUsability && !forceDefault,
		[styles.richMediaInDrawer]: containerType === 'line-item' && isCartUsability && isMinicart,
		[styles.richMediaLarge]: containerType === 'large',
	});

	const imgClasses = cn(styles.richMediaImg, {
		[styles.richMediaImgTop]: variant?.product?.type?.includes(PRODUCT_TYPES.TOP_FRAME),
		[styles.richMediaImgAccessory]: variant?.product?.type?.includes(PRODUCT_TYPES.ACCESSORY) && pageType != 'search',
		[styles.richMediaImgSearchAccessory]: variant?.product?.type?.includes(PRODUCT_TYPES.ACCESSORY) && pageType === 'search',
		[styles.richMediaImgGiftCard]: variant?.product?.type?.includes(PRODUCT_TYPES.GIFT_CARD),
		[styles.richMediaImgLineItem]: containerType === 'line-item',
	});

	const image = ({ src, alt, style = {} }: imageProps) => (
		<Img
			className={imgClasses}
			onClick={ImgClickHandler}
			src={src}
			alt={alt}
			sizes='(min-width: 76.8em) 24em, 10em'
			aspectRatio={aspectRatio}
			needsPlaceholder
			style={style}
		/>
	);

	const imagesWrapper = (
		<div className={styles.imgWrapper} style={{ aspectRatio }}>
			{image({ src, alt: `${title} - non-hovered`, style: { opacity: isHovered ? 0 : 1 } })}
			{hoverSrc &&
				image({
					src: hoverSrc,
					alt: `${title} - hovered`,
					style: { opacity: isHovered ? 1 : 0, objectFit: 'cover' },
				})}
		</div>
	);

	return (
		<>
			{dispatch && secondaryAction === 'vto' && isVTOonCarouselImage && (
				<Modal.Trigger asChild>
					<Flex justify='center' className={styles.vtoButtonContainer}>
						<Button
							color='white'
							extraClasses={styles.vtoButton}
							data-tryon={title}
							onClick={() => {
								dispatch({
									type: 'product',
									option: {
										product,
										variantIdentifier: variant?.option,
									},
								});
							}}
						>
							<VideoIcon width={16} height={16} /> Virtual Try-On
						</Button>
					</Flex>
				</Modal.Trigger>
			)}
			<ConditionalLink
				href={href}
				title={`Go to ${title} product page`}
				className={containerClasses}
				style={{ aspectRatio }}
				data-image-link={title}
				condition={!!href}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				{isMobile ? (
					hoverSrc ? (
						<Swiper
							key={swiperKey}
							autoplay={false}
							className={styles['swiper-container']}
							modules={[Navigation, Pagination, Zoom]}
							navigation={navigationRefs}
							pagination={DEFAULT_SWIPER_PROPS.pagination}
							{...DEFAULT_SWIPER_PROPS}
						>
							<SwiperSlide className={styles['slide-container']}>{image({ src })}</SwiperSlide>
							<SwiperSlide>{image({ src: hoverSrc, style: { objectFit: 'cover' } })}</SwiperSlide>
							<div className={cn('swiper-pagination', styles['swiper-pagination'])} />
						</Swiper>
					) : isModalPresent ? (
						<Modal.Trigger asChild>{imagesWrapper}</Modal.Trigger>
					) : (
						imagesWrapper
					)
				) : (
					imagesWrapper
				)}
				{variant?.metafields?.discountTag && isVerticalCard && (
					<div className={styles.saveTag}>
						<SaveTag label={'save-tag'} detail={variant.metafields.discountTag} />
					</div>
				)}
			</ConditionalLink>
		</>
	);
};

export default RichMedia;
