import { useRouter } from 'next/router';
import { Flex, Spacer } from '@components';
import { Paragraph, Title, TypographyButton } from '@components/typography';
import { NormalizedVariant } from '@ts/index';
import { formatCurrency } from '@utils/shopify';
import { LOCALE_CODES } from '@constants';
import PairCareTooltip from '../PairCareTooltip/PairCareTooltip';
import styles from './PairCareBody.module.scss';

type PairCareBodyProps = {
	variant: NormalizedVariant;
};

const PairCareBody = ({ variant }: PairCareBodyProps) => {
	const { locale } = useRouter();
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;

	return (
		<Flex column fullWidth>
			<Flex fullWidth justify='between'>
				<div>
					<Title className={styles.title}>
						<span>Pair</span>
						<span>Care</span>
					</Title>
				</div>
				<Flex style={{ height: 'fit-content', flexShrink: '0' }} align='center' gap={3}>
					<PairCareTooltip />
					<TypographyButton small className={styles.price}>
						{variant?.price &&
							formatCurrency(
								{ amount: variant?.price?.amount, currencyCode: variant?.price?.currencyCode, locale },
								showCurr
							)}
					</TypographyButton>
				</Flex>
			</Flex>
			<Spacer size={'0.6rem'} />
			<Paragraph>1-year warranty that offers full protection for scratched lenses or damaged frames.</Paragraph>
		</Flex>
	);
};

export default PairCareBody;
