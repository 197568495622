import { Flex, KlarnaPlacement, SubtotalHeader } from '@components';
import styles from './BuyBox.module.scss';
interface BuyBoxProps {
	isMinicart: boolean;
	isMobile: boolean;
	subtotal: number;
	totalQuantity: number;
}
const BuyBox = ({ isMinicart, isMobile, subtotal, totalQuantity }: BuyBoxProps) => (
	<Flex maxWidth justify='between' align='center' className={!isMinicart && styles.container}>
		<SubtotalHeader
			buyBox
			subtotal={subtotal}
			itemCount={totalQuantity ?? 0}
			disableSubtotalBold={isMobile || isMinicart}
			disableItemsCount={isMinicart}
		/>
		{isMinicart && (
			<div className={isMinicart && isMobile && styles.klarnaPlacement}>
				<KlarnaPlacement subtotal={subtotal} />
			</div>
		)}
	</Flex>
);

export default BuyBox;
