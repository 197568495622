const es = {
  // =============== A ===============
  "add": "",
  "add-to-cart": "",
  "add-a-top-frame": "",
  "adding": "",
  "add-to-cart-go-to-tops": "",
  "all-time": "",
  "accessories": "",
  "added": "",
  "account-card-list-items": ({ itemsNumber }: { itemsNumber: string }) => `${itemsNumber}`,
  "account-card-list-item": ({ itemNumber }: { itemNumber: string }) => `${itemNumber}`,
  // =============== B ===============
  "back": "",
  "build-purchase-edit-frame-save": "",
  "base-frames": "",
  "back-to-my-orders": "",
  // =============== C ===============
  "color": "",
  "collection": "",
  "collection-uppercase": "",
  "collection-title": ({ collectionTitle }: { collectionTitle: string }) => `${collectionTitle}`,
  "close": "",
  "close-uppercase": "",
  "contact-customer-service": "",
  "contact-us": "",
  "contact-us-button-tittle": "",
  "check-readers-strength": "",
  "choose-lens-type": "",
  "choose-subscription": "",
  "choose-top-frames": "",
  "choose-additional-tops": "",
  // =============== D ===============
  "download-invoice": "",
  "design": "",
  // =============== E ===============
  "edit-bundle": "",
  "edit-pair": "",
  "exit": "",
  "eye-doctor-contact": "",
  "entered-manually": "",
  "earn-more-points": "",
  "explore-all-tops": "",
  "explore-favorites": "",
  // ============================== Errors =============================================
  "error-loading-order-details-message": "",
  "error-subscription-submission-upload": "",
  "error-subscription-submission-file-type": "",
  "fetching-invoice": "",
  // =============== F ===============
  "favorites": "",
  // =============== G ===============
  "got-it": "",
  "go-to-rewards": "",
  "grateful-phrase-PariCarse": "",
  "get-frames-faster": "",
  // =============== H ===============
  "how-redeem-points": "",
  // =============== I ===============
  "items-in-order": "",
  "included": "",
  // =============== L ===============
  "last-action-error": "",
  "last-3-months": "",
  "last-6-months": "",
  "loading": "",
  "loading-collections": "",
  // =============== M ===============
  "make-a-selection": "",
  "manually-prescription": "",
  "method": "",
  "my-tab-name": ({ tabName }: { tabName: string }) => `${tabName}`,
  "my-collection-name": ({ collectionName }: { collectionName: string }) => `${collectionName}`,
  "my-favorites": "",
  "my-collection": "",
  "my-orders": "",
  "my-rewards": "",
  "my-subscriptions": "",
  "my-empty-collection-title": "",
  "my-empty-collection-paragraph": "",
  // =============== N ===============
  "no-order-found": "",
  "no-order-found-message": "",
  "no-orders-in-time-period": "",
  "no-orders-found": "",
  "no-favorites-heading": "",
  "no-favorites-body": "",
  // =============== O ===============
  "open-minicart": "",
  "orders": "",
  "order-line-items": ({ orderItems }: { orderItems: string }) => `${orderItems}`,
  "orders-placed": "",
  "order-placed": "",
  "order-tracker": "",
  "ordered-date": ({ orderedDate }: { orderedDate: string }) => `${orderedDate}`,
  "order-number-message": ({ orderNumber }: { orderNumber: string }) => `${orderNumber}`,
  "ordered-previously": "",
  "our-specialty": "",
  // =============== P ===============
  "past-year": "",
  "prescription-photo-uploaded": "",
  "pair-points-reward": ({ pointsBalance }: { pointsBalance: string }) => `${pointsBalance}`,
  "pair-points-until-reward": ({ pointsBalance, nextRewardPoints }: { pointsBalance: string, nextRewardPoints: string }) => `${pointsBalance}/${nextRewardPoints}`,
  "pair-care-upsell-state-available-line-1": "",
  "pair-care-upsell-state-available-line-2": "",
  "pair-care-upsell-state-ineligible-line-1": "",
  "pair-care-upsell-state-ineligible-line-2": "",
  "pair-care-upsell-state-purchased-line-1": "",
  "pair-care-upsell-state-purchased-line-2": "",
  "pair-care-upsell-state-pending-line-1": "",
  "pair-care-upsell-state-pending-line-2": "",
  "pd-already-measured": "",
  "pd-already-measured-message": "",
  "pd-not-needed": "",
  "pd-not-needed-message": "",
  "plus-add": "",
  "promotion-code": ({ code }: { code: string }) => `${code}`,
  "pending": "",
  "protect-base": "",
  "prescription-details-header": "",
  "prescription-details-paragraph": ({ productTitle, variantTitle }: { productTitle: string; variantTitle: string }) => `${productTitle} ${variantTitle}`,
  // =============== R ===============
  "rewards": "",
  "readers-strength-error": "",
  "recent-order": "",
  "reward-message-less-than-50": "",
  "reward-message-50-99": "",
  "reward-message-100-199": "",
  "reward-message-200-299": "",
  "reward-message-300-399": "",
  "reward-message-400-499": "",
  "reward-message-500-599": "",
  "reward-message-600-999": "",
  "reward-message-1000+": "",
  // =============== S ===============
  "save-bundle-edits": "",
  "subscriptions": "",
  "sent-exclamation": "",
  "select-one-or-more": "",
  "selected": "",
  "shop-glasses": "",
  "suggested-looks": "",
  "shop-tops": "",
  "status": "",
  "subtotal": "",
  "select-collection": "",
  "select-all-that-apply": "",
  "select": "",
  // =============== T ===============
  "tax": "",
  "tax-included": "",
  "tap-reward-to-learn": "",
  "total": "",
  "total-discount": "",
  "top-frames": "",
  "tracking-number": "",
  "trouble-saving-info": "",
  "tops-made-for-you": "",
  // =============== U ===============
  "upload-prescription": "",
  "update-cart": "",
  // =============== V ===============
  "variant-unavailable": "",
  "variant-unavailable-message": "",
  "view-my-favorites": "",
  "view-order-details": "",
  // =============== W ===============
  "welcome-back-text": ({ customerName }: { customerName: string }) => `${customerName}`,
  "whoops": "",
  // =============== Y ===============
  "your-reward-level": "",
  "your-friends-mail": "",
};

export default es;
