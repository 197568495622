import { ComponentProps } from 'react';
import { CartReview, Flex } from '@components';

const mockedReviews = [
	{
		description:
			'This is my first Pair purchase and now I know why they’re so popular! The quality is great and the ability to keep multiple looks in one eyeglass case is genius.',
		name: 'Anna',
		location: 'California',
		rating: 5,
	},
	{
		description:
			'They have been really resilient against scratches. 1000/10 would recommend to anyone looking for affordable, yet durable glasses.',
		name: 'Colby',
		location: 'Maine',
		rating: 5,
	},
	{
		description:
			'The prescription is accurate, the frames fit well and the toppers are so fun. Customer service was great and shipping was fast. Highly recommend trying them out!',
		name: 'Kelsey',
		location: 'Indiana',
		rating: 5,
	},
];
interface CartReviewsProps {
	reviews?: ComponentProps<typeof CartReview>[];
}
function CartReviews({ reviews = mockedReviews }: CartReviewsProps) {
	return (
		<Flex column fullWidth gap={4} style={{ marginTop: '1.6rem' }}>
			{reviews.map((review, index) => (
				<CartReview {...review} key={`${review.name}--${index}`} />
			))}
		</Flex>
	);
}

export default CartReviews;
