import { useRouter } from 'next/router';
import { Flex, SwatchController, SwatchOption } from '@components';
import { FRAME_COLOR_SORTING, FRAME_COLORS, LENS_COLORS, PRODUCT_TYPES } from '@constants';
import { BaseFrameState } from '@utils/hooks/useBaseFrame';
import { normalizeProductType } from '@utils/normalizers';

const VariantController = ({ product, state, dispatch, showLensController }) => {
	const { query } = useRouter();
	const isMetalBaseFrame = query?.base === 'Metal' || product.handle.includes('mixed-material');
	const type = normalizeProductType(product.type);

	const frameColors: Array<SwatchOption> = [];
	const metalFrameColors: string[] = [];

	product.variants.forEach(variant => {
		const color = variant.option;
		const shouldAddNewTag =
			color === FRAME_COLORS.GRAY_CLEAR || color === FRAME_COLORS.HONEY_CLEAR || color === FRAME_COLORS.LAVENDER_CLEAR;

		if (color === FRAME_COLORS.MIX_BLACK_GOLD || color === FRAME_COLORS.MIX_CRYSTAL_SILVER) {
			metalFrameColors.push(color);
		} else {
			frameColors.push({ name: color, tag: shouldAddNewTag ? { label: 'New!', type: 'success' } : null });
		}
	});

	if (!(product.variants.length > 0)) return null;

	switch (type) {
		case PRODUCT_TYPES.BASE_FRAME || PRODUCT_TYPES.BASE_FRAME_SR:
			return (
				<>
					<Flex style={{ flex: 1 }} gap={3} center>
						<SwatchController
							options={
								isMetalBaseFrame
									? metalFrameColors
									: frameColors.sort((a, b) => FRAME_COLOR_SORTING[a.name] - FRAME_COLOR_SORTING[b.name])
							}
							callback={dispatch}
							name={product.name}
							selected={(state as BaseFrameState).frame}
							type={'frame'}
							alignment='left'
							activateOnHover
							parent={'product-card'}
						/>
					</Flex>
					{showLensController && (
						<SwatchController
							options={Object.values(Object.values(LENS_COLORS))}
							callback={dispatch}
							name={product.name}
							selected={(state as BaseFrameState).lens ?? LENS_COLORS.BLACK}
							title={'with'}
							type={'lens'}
							alignment='left'
							activateOnHover
							parent={'product-card'}
						/>
					)}
				</>
			);

		default:
			return null;
	}
};

export default VariantController;
