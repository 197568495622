import cn from 'classnames';
import { ReactNode } from 'react';
import { NormalizedVariant } from '@ts/product';
import { Flex } from '@components';
import styles from './CardContent.module.scss';

type ContentProps = {
	containerType?: 'vertical' | 'horizontal' | 'line-item' | 'build-flow';
	type?: 'vertical' | 'horizontal' | 'micro' | 'vertical-micro';
	compact?: boolean;
	justify?: 'center' | 'start' | 'end' | 'between' | 'around' | 'evenly' | 'normal';
	showTags?: boolean;
	children: ReactNode;
	hoverable?: boolean;
	noBorder?: boolean;
	variant?: NormalizedVariant;
	isCartUsability?: boolean;
	large?: boolean;
	isMinicart?: boolean;
};

const Content = ({
	containerType,
	type = 'vertical',
	compact,
	noBorder,
	hoverable,
	children,
	showTags,
	large,
	isMinicart,
	isCartUsability = false,
}: ContentProps) => {
	const classes = cn(styles.content, {
		[styles.contentVertical]: type === 'vertical',
		[styles.contentVerticalMicro]: type === 'vertical-micro',
		[styles.contentVerticalMicroWithTags]: type === 'vertical-micro' && showTags,
		[styles.contentHorizontal]: type === 'horizontal',
		[styles.contentMicro]: type === 'micro',
		[styles.contentMicroWithTags]: type === 'micro' && showTags,
		[styles.contentCompact]: compact,
		[styles.contentNoBorder]: noBorder,
		[styles.contentHoverable]: hoverable,
		[styles.contentBuildFlow]: containerType === 'build-flow',
		[styles.contentBorderLeft]: containerType === 'horizontal' && type === 'vertical',
		[styles.contentMicroCartUsability]: type === 'micro' && isCartUsability,
		[styles.contentLarge]: large,
		[styles.contentLargeIsMinicart]: isMinicart && large,
	});

	// Utilizes CSS Grid instead of Flexbox for "micro" cards
	return type === 'micro' ? (
		<div className={classes}>{children}</div>
	) : (
		<Flex fullWidth column={type.includes('vertical')} align='start' className={classes}>
			{children}
		</Flex>
	);
};

export default Content;
