/* eslint-disable prettier/prettier */
/* eslint-disable max-lines */
import { useMemo, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper/modules';
import { useRouter } from 'next/router';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import cn from 'classnames';
import {
	CUSTOMER_JOURNEYS,
	DELETE_BASE_FRAME,
	LENSES_SUPPORT_COPY,
	LOCALE_CODES,
	PRODUCT_TYPES,
	READER_STRENGTHS,
	SUBMISSION_METHODS,
} from '@constants';
import {
	Button,
	Caption,
	CartPD,
	CheckboxUpsell,
	Flex,
	Heading,
	Img,
	LineItemCard,
	Loading,
	Modal,
	PairCare,
	PairCareTooltip,
	Paragraph,
	PrescriptionOptions,
	Select,
	Title,
	Trash,
	TypographyButton,
} from '@components';
import { useCart, useProductQuery } from '@services/shopify';
import { Base, Bundle, DoctorInfoAttributes, NormalizedCartLine } from '@ts/cart';
import { SubmissionMethod } from '@ts/poms';
import { NormalizedProduct } from '@ts/product';
import { CurrencyCode } from '@ts/shopify-storefront-api';
import {
	BADGE_TYPES,
	findLensPackageInLensOptions,
	formatCurrency,
	FRAME_COLORS,
	generateSunglassImages,
	getBaseName,
	getCheckoutTokenFromGID,
	LENS_COLORS,
	LOCALE_DICT,
	useParseBaseFrameVariant,
} from '@utils/index';
import variables from '@styles/export.module.scss';
import { CART_LINE_ATTRIBUTE_KEYS } from '@utils/constants/cart';
import { useCartContext } from '@context';
import useBaseFrameVariant, {
	createBaseFrameVariant,
	getVariantPriceDiff,
	useBaseFrameVariantPrices,
} from '@services/shopify/hooks/useBaseFrameVariant';
import { BASE_FRAME_LENS_OPTIONS, RX_TYPE } from '@utils/constants/base-skus';
import 'swiper/css';
import 'swiper/css/effect-fade';
import { useFeatureInLocale } from '@utils/hooks';
import {
	addCustomAttributes,
	getCollectionPathFromProperties,
	getEditPath,
	getReaderStrengthPayload,
	getSubmissionMethodPayload,
	removeCustomAttributes,
} from '../cart-utils';
import { emptyUpsellLine, emptyUpsellProduct } from '../cart-constants';
import styles from './BaseFrameBundle.module.scss';

const { REMIND } = SUBMISSION_METHODS;

type BaseFrameBundleProps = {
	cartId: string;
	bundle: Bundle;
	isLoading: boolean;
	pairCare: NormalizedProduct;
	forceMobileStyle?: boolean;
	tags?: (typeof BADGE_TYPES.TOP_RATED)[];
};

const BaseFrameBundle = ({ bundle, pairCare, forceMobileStyle = false, isLoading, tags }: BaseFrameBundleProps) => {
	const router = useRouter();
	const { locale } = router;

	const isBothUpsellsVariant = useFeatureIsOn('both-upsells-test');
	const isPremiumVariant = useFeatureIsOn('show-premium-upsell');
	const isCartUsability = useFeatureIsOn('is-cart-usability') && locale === 'en-US';
	const isCartPD = useFeatureInLocale('is-cart-pd-test', LOCALE_CODES.US);
	const isCartUpsellTest = isBothUpsellsVariant || isPremiumVariant;

	const { data: cart } = useCart();
	const hasPairCareSelected = !!bundle?.base?.insurance;
	const { handleCartRemove, handleCartUpdate } = useCartContext();
	const countryCode = LOCALE_DICT[locale].countryCode;
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;

	const { base, optimistic: optimisticState, key: bundleKey, subscriptionProduct } = bundle;
	const withSubscription = !!subscriptionProduct;
	const { frame, prescription, insurance } = base;
	const { variant, properties, id: frameId } = frame;
	const {
		handle = "",
		option: fullVariantOption,
		name: productFrameTitle,
		type: varianttype,
		image: variantImg,
		name: frameTitle,
	} = variant;
	const { url: variantImgUrl } = variantImg;
	const { _customerJourney, _readerStrength, _submissionMethod = REMIND } = properties;
	const { id: insuranceId } = insurance ?? { id: "" };
	const { type: prescriptionType } = prescription;
	const isSunglasses = _customerJourney === CUSTOMER_JOURNEYS.SUNGLASSES;
	const baseFrameHeadCount = cart?.lines?.filter((line) => line?.variant?.type?.includes(PRODUCT_TYPES.BASE_FRAME)).length;

	const { frameColor, lensColor, lensType, rxType } = useParseBaseFrameVariant(fullVariantOption);
	const lensTypesToDisplay = lensType.filter((lens) => {
		if (!isSunglasses) {
			return lens !== BASE_FRAME_LENS_OPTIONS.CR39 && lens !== BASE_FRAME_LENS_OPTIONS.PREMIUM_PLUS;
		}
		return lens !== BASE_FRAME_LENS_OPTIONS.CR39 && lens !== BASE_FRAME_LENS_OPTIONS.PREMIUM_PLUS;
	});

	const isBlueLensInCart = lensType.includes(BASE_FRAME_LENS_OPTIONS.BLUE_LIGHT);
	const isLightResponsiveInCart = lensType.includes(BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE);
	const isNonRx = rxType === "Non-RX";
	const isReaders = rxType === "Readers";
	const isBlueLightUpsellVisible = !isBlueLensInCart && !isSunglasses && (!isPremiumVariant || !isCartUpsellTest);
	const isRxOptionsVisible = !!bundleKey && !isLoading && !isNonRx && !isReaders;
	const showUpsellIncentiveCopy = !isSunglasses && isBlueLightUpsellVisible && !isLightResponsiveInCart;
	const sunGlasses = [];
	const prescriptionLensPrice = { amount: 0, currencyCode: CurrencyCode.Usd };
	const submissionMethodState = useState(_submissionMethod as SubmissionMethod);
	const { data: product } = useProductQuery(handle, { country: countryCode });
	const variantImages = product?.variantImages;
	const lensPack = !isSunglasses ? findLensPackageInLensOptions(lensType) : null;
	const hasBlueLight = lensType.includes(BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE);
	const allLenses = [lensPack, hasBlueLight && BASE_FRAME_LENS_OPTIONS.BLUE_LIGHT, ...lensTypesToDisplay].filter(Boolean);

	const lensesLabel = [
		locale === "en-GB" && rxType === RX_TYPE.PROGRESSIVE ? "Varifocal" : rxType,
		allLenses.length > 0 ? allLenses.join(" - ") : null,
	]
		.filter(Boolean)
		.join(" | ");

	const BaseFrameVariant = useBaseFrameVariant({
		handle: base.frame.variant.handle,
		Color: frameColor,
		"Rx Type": rxType,
		Lens: createBaseFrameVariant(lensType),
		country: countryCode,
	});

	const { data: variantPrices, isLoading: variantPricesAreLoading } = useBaseFrameVariantPrices(
		base.frame.variant.handle,
		countryCode,
	);

	const sunglassImages = useMemo(
		() =>
			generateSunglassImages({
				name: getBaseName(productFrameTitle),
				baseColor: frameColor as FRAME_COLORS,
				lensColor: (isSunglasses ? lensColor : "") as LENS_COLORS,
				length: 3,
			}),
		[frameColor, sunGlasses[0]?.variant?.option, lensColor],
	);

	const removeAllFromBundle = () => {
		handleCartRemove(
			[...(insuranceId && [insuranceId]), ...(withSubscription ? [subscriptionProduct?.id] : []), frameId],
			variant,
			getCollectionPathFromProperties(properties),
		);
	};

	const updateReaderStrength = (strength: (typeof READER_STRENGTHS)[number]) => {
		const payload = getReaderStrengthPayload(strength, frame, properties);
		handleCartUpdate([payload]);
	};

	const updatePDMeasurement = async (pd: number | null) => {
		const payload = !pd
			? removeCustomAttributes(CART_LINE_ATTRIBUTE_KEYS.PD_MEASUREMENT, frame, properties)
			: addCustomAttributes(
					{
						key: CART_LINE_ATTRIBUTE_KEYS.PD_MEASUREMENT,
						value: pd.toString(),
					},
					frame,
					properties,
				);
		handleCartUpdate([payload]);
	};

	const updateBundleSubmissionMethod = (extraProperties: DoctorInfoAttributes = {}) => {
		const isEditableBaseFrame = !properties || !frameId;
		const isSubmissionChanging = submissionMethodState[0] === _submissionMethod;

		if (isEditableBaseFrame || isSubmissionChanging) return;

		const payload = getSubmissionMethodPayload(properties, submissionMethodState[0], frame, extraProperties);
		frame && handleCartUpdate([payload]);
	};

	const memoizedRxOptions = useMemo(() => {
		return (
			<PrescriptionOptions
				updatePDMeasurement={updatePDMeasurement}
				updateBundleSubmissionMethod={updateBundleSubmissionMethod}
				submissionMethodState={submissionMethodState}
				bundleKey={bundleKey}
				checkoutToken={getCheckoutTokenFromGID(cart?.id)}
				hasMeasuredPd={!!properties[CART_LINE_ATTRIBUTE_KEYS.PD_MEASUREMENT]}
				forceMobileStyle={forceMobileStyle}
			/>
		);
	}, [base, submissionMethodState, isLoading]);

	const baseFrameImages = (image, index) => (
		<SwiperSlide key={index} className={"swiper-no-swiping"}>
			<Flex center>
				<Img
					needsPlaceholder
					alt={image.altText ? `${productFrameTitle} - ${image.altText}` : productFrameTitle}
					src={image.url}
					height={155}
					width={311}
					style={{ background: variables.gray0 }}
				/>
			</Flex>
		</SwiperSlide>
	);

	const handleEdit = async () => {
		const { basePath, queryParams } = getEditPath(
			bundleKey,
			frameColor,
			properties,
			_customerJourney,
			handle,
			withSubscription,
		);
		const currentPath = router.asPath?.split("?")[0];

		if (currentPath === basePath) document.getElementById("minicart")?.click();

		router.push(basePath + queryParams);
	};

	if (!base) return null;
	if (isLoading || BaseFrameVariant.isLoading) return <Loading />;

	const lenses = (
		<>
			{/* LENSES */}
			{lensTypesToDisplay.map((lensLine) => {
				return (
					<CheckboxUpsell
						disabled={isSunglasses}
						key={`lensLine-${lensLine}`}
						base={base}
						bundleKey={bundleKey}
						tooltip
						lensUpsell={lensLine}
						variantPrices={variantPrices}
						currentVariant={BaseFrameVariant}
						isRedesign={isCartUpsellTest && !isSunglasses}
						lensPack={lensPack}
					/>
				);
			})}
			{/* LENS UPSELLS */}
			{isBlueLightUpsellVisible && (
				<CheckboxUpsell
					base={base}
					lensUpsell={BASE_FRAME_LENS_OPTIONS.BLUE_LIGHT}
					bundleKey={bundleKey}
					variantPrices={variantPrices}
					currentVariant={BaseFrameVariant}
					isRedesign={isCartUpsellTest}
					disabled={isLightResponsiveInCart}
					lensPack={lensPack}
				/>
			)}
		</>
	);

	const lensPackPriceDiff =
		!isSunglasses && !variantPricesAreLoading
			? getVariantPriceDiff({
					lookup: variantPrices,
					current: BaseFrameVariant,
					lensPackage: lensPack,
					rxType,
					locale,
					currencyCode: LOCALE_DICT[locale].currencyCode,
				})
			: null;

	const rxPriceDiff =
		!isSunglasses && !variantPricesAreLoading
			? getVariantPriceDiff({
					lookup: variantPrices,
					current: BaseFrameVariant,
					rxType,
					locale,
					currencyCode: LOCALE_DICT[locale].currencyCode,
				})
			: null;
	const totalPackPrice =
		!isSunglasses && !!rxPriceDiff && !!lensPackPriceDiff
			? {
					with: {
						...lensPackPriceDiff.with,
						amount: lensPackPriceDiff.with.amount + rxPriceDiff.with.amount,
					},
				}
			: null;

	const removePairCareFromCart = () => {
		const pairCareId = cart?.lines.find((line) => {
			return line?.variant?.handle === 'pair-care';
		})?.id;
		handleCartRemove([pairCareId]);
	};

	function updateFramePrice(frame: NormalizedCartLine, base: Base, isCartUsability: boolean) {
		if (!isCartUsability) return frame;

		return {
			...frame,
			variant: {
				...frame.variant,
				price: {
					...frame.variant.price,
					amount: frame.variant.price.amount + (base.insurance?.variant.price.amount ?? 0),
				},
			},
		};
	}

	return (
		<Flex
			column
			gap={4}
			maxWidth
			className={
				forceMobileStyle ? styles.containerInDrawer : isCartUsability ? styles.usabilityContainer : styles.container
			}
		>
			<Flex justify={isCartUsability ? "between" : "end"} align='center'>
				{isCartUsability && <Caption className={styles.title}>My Base Frame</Caption>}
				<Flex justify='end' gap={4}>
					<Button size='small' linkStyle onClick={handleEdit} dataTags={{ "data-cart-edit": handle }}>
						Edit
					</Button>
					<TypographyButton small style={{ color: variables.gray4 }}>
						|
					</TypographyButton>
					<Modal>
						<Modal.Trigger asChild>
							<Button size='small' linkStyle data-open-remove-modal={productFrameTitle}>
								Remove
							</Button>
						</Modal.Trigger>
						<Modal.Content center removePadding className={styles.deleteBaseFrameModal}>
							<Flex className={styles.imageContainer} align='center' justify='center'>
								<img src={isSunglasses ? sunglassImages[0].url : variantImgUrl} alt='frame variant' />
							</Flex>
							<div className={styles.bodyContainer}>
								<Heading tag='h5'>{DELETE_BASE_FRAME.TITLE}</Heading>
								<Paragraph>
									{DELETE_BASE_FRAME.LABEL} <b>{productFrameTitle}</b>.{" "}
									{withSubscription ? DELETE_BASE_FRAME.PROMPT_WITH_SUBSCRIPTION : DELETE_BASE_FRAME.PROMPT}
								</Paragraph>
								<Flex gap={3}>
									<Modal.Close asChild>
										<Button color='white'>Cancel</Button>
									</Modal.Close>
									<Modal.Close asChild>
										<Button
											onClick={() => removeAllFromBundle()}
											style={{ flex: 2 }}
											data-remove={productFrameTitle}
											data-type-remove={varianttype}
										>
											Remove
										</Button>
									</Modal.Close>
								</Flex>
							</div>
						</Modal.Content>
					</Modal>
				</Flex>
			</Flex>
			{!!variantImages && !isLoading && !isCartUsability && (
				<Flex center>
					<Swiper
						loop
						direction='horizontal'
						speed={600}
						modules={[Autoplay, EffectFade]}
						effect='fade'
						autoplay={{ delay: 2500 }}
						noSwiping
						className={styles["swiper-container"]}
					>
						{isSunglasses
							? sunglassImages &&
								sunglassImages.map((sunglassImage, index) => baseFrameImages(sunglassImage, index))
							: variantImages && variantImages[frameColor].map((color, index) => baseFrameImages(color, index))}
					</Swiper>
				</Flex>
			)}
			{!isCartUsability && <Paragraph className={styles.bundleTitle}>My Base Frame</Paragraph>}
			{base && !isLoading && (
				<>
					<Flex gap={4} column={isCartUsability && forceMobileStyle}>
						{isCartUsability && (
							<Swiper
								loop
								direction='horizontal'
								speed={600}
								modules={[Autoplay, EffectFade]}
								effect='fade'
								autoplay={{ delay: 2500 }}
								noSwiping
								style={{ maxWidth: forceMobileStyle ? "33rem" : "" }}
								className={cn(styles["swiper-container"], {
									[styles["swiper-container-minicart"]]: forceMobileStyle,
								})}
							>
								{isSunglasses
									? sunglassImages &&
										sunglassImages.map((sunglassImage, index) => baseFrameImages(sunglassImage, index))
									: variantImages &&
										variantImages[frameColor].map((color, index) => baseFrameImages(color, index))}
							</Swiper>
						)}
						<LineItemCard
							key={frameTitle}
							data={updateFramePrice(frame, base, isCartUsability)}
							dataSource={"shopify"}
							deletionCallback={removeAllFromBundle}
							optimistic={optimisticState}
							baseFrame={base}
							showTags
							textOnly
							customTags={tags}
							positionTag='right'
						/>
					</Flex>
					{!isCartUsability && (
						<Flex column gap={2} fullWidth className={isCartUpsellTest || !isSunglasses ? styles.section : null}>
							{(isCartUpsellTest || !isSunglasses) && <Caption>Protect Your Lenses</Caption>}
							{!isCartUsability && <PairCare bundle={bundle} pairCare={pairCare} />}
						</Flex>
					)}
				</>
			)}
			{_readerStrength && (
				<Flex align='center' justify='between' className={styles.readerStrengthDropdown}>
					<Title>Reader Strength</Title>
					<Select
						values={READER_STRENGTHS.map((rs) => `+${rs}`)}
						handler={updateReaderStrength}
						placeholder={`+${_readerStrength}`}
					/>
				</Flex>
			)}
			{!isCartUsability ? (
				<Flex column gap={4} fullWidth className={styles.section}>
					{!isCartUpsellTest && (
						<Flex justify='between' align='end'>
							<Flex column>
								<Paragraph className={styles.bundleTitle} style={{ paddingLeft: 0, marginTop: 0 }}>
									My Lenses
								</Paragraph>
								{!isSunglasses && (
									<Paragraph className={styles.lensPackage}>
										{locale === "en-GB" && rxType === RX_TYPE.PROGRESSIVE ? "Varifocal" : rxType} in{" "}
										{lensPack}
									</Paragraph>
								)}
							</Flex>
							{!isSunglasses && totalPackPrice ? (
								<TypographyButton small style={{ color: variables.gray4 }}>
									{formatCurrency(totalPackPrice?.with, showCurr)}
								</TypographyButton>
							) : (
								<div />
							)}
						</Flex>
					)}
					{isCartUpsellTest && (
						<Flex justify='between' align='end'>
							<Flex column>
								<Caption>My Lenses</Caption>
								{!isSunglasses && (
									<Paragraph className={styles.lensPackage}>
										{locale === "en-GB" && rxType === RX_TYPE.PROGRESSIVE ? "Varifocal" : rxType} in{" "}
										{lensPack}
									</Paragraph>
								)}
							</Flex>
							{!isSunglasses && totalPackPrice ? (
								<TypographyButton small style={{ color: variables.gray4 }}>
									{formatCurrency(totalPackPrice?.with, showCurr)}
								</TypographyButton>
							) : (
								<div />
							)}
						</Flex>
					)}
					{/* RX TYPE */}
					{isSunglasses && (
						<CheckboxUpsell
							className={isCartUpsellTest ? styles.transparent : null}
							disabled
							base={base}
							product={{
								...emptyUpsellProduct,
								name: rxType ?? prescriptionType,
								handle: rxType,
								price: prescriptionLensPrice,
							}}
							bundleKey={bundleKey}
							line={{
								...emptyUpsellLine,
								title: rxType,
							}}
							preSelectedTooltip
							rxType={rxType}
							variantPrices={variantPrices}
							currentVariant={BaseFrameVariant}
						/>
					)}
					{showUpsellIncentiveCopy && isCartUpsellTest && (
						<Flex justify='start' align='start' fullWidth gap={3} className={styles.lensUpsellIncentive}>
							<Caption small>{LENSES_SUPPORT_COPY.blueLight}</Caption>
						</Flex>
					)}
					{!variantPricesAreLoading && lenses}
				</Flex>
			) : (
				<Flex column gap={4}>
					<Flex column gap={4} fullWidth className={styles.usabilitySection}>
						{!isCartUpsellTest && (
							<Flex justify='between' align='end'>
								{
									<Flex align='center' gap={3}>
										<Paragraph className={styles.bundleTitle} style={{ paddingLeft: 0, marginTop: 0 }}>
											My Lenses
										</Paragraph>
										{" - "}
										<Paragraph className={styles.lensPackage}>{lensesLabel}</Paragraph>
									</Flex>
								}
							</Flex>
						)}
					</Flex>
					{hasPairCareSelected && (
						<Flex column gap={4} fullWidth className={styles.usabilitySection}>
							<Flex justify='between' align='end'>
								<Flex fullWidth justify='between' align='center'>
									<Flex column={false} gap={3} align='center'>
										<Paragraph className={styles.bundleTitle} style={{ paddingLeft: 0, marginTop: 0 }}>
											Protect your lens
										</Paragraph>
										<PairCareTooltip iconType='information' />
										{' - '}
										<Paragraph className={styles.lensPackage}>Pair Care</Paragraph>
									</Flex>
									<Flex align='center' className={styles.info} gap={3} data-cart-checkbox-remove='PAIRCare'>
										<Trash callback={removePairCareFromCart} />
										<Paragraph>${base.insurance?.variant.price.amount ?? 0}</Paragraph>
									</Flex>
								</Flex>
							</Flex>
						</Flex>
					)}
				</Flex>
			)}
			{isRxOptionsVisible && memoizedRxOptions}
			{isCartPD && baseFrameHeadCount === 1 && (
				<CartPD
					callback={updatePDMeasurement}
					pdMeasurement={bundle.base.frame.properties[CART_LINE_ATTRIBUTE_KEYS.PD_MEASUREMENT]}
				/>
			)}
		</Flex>
	);
};

export default BaseFrameBundle;
