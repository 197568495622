import React, { CSSProperties, forwardRef, ReactNode } from 'react';
import { useRouter } from 'next/router';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { CART_USABILITY_ALLOWED_PRESCRIPTION_UPLOAD_EXTENSIONS, SUBMISSION_METHODS } from '@constants';
import {
	Button,
	Caption,
	Divider,
	Flex,
	Lozenge,
	Paragraph,
	RadioGroupStyles as radioStyles,
	Title,
	Trash,
	DragAndDropFileUpload,
} from '@components';
import variables from '@styles/export.module.scss';
import styles from './PrescriptionOption.module.scss';

type PrescriptionOptionProps = {
	title: string;
	lozenge?: {
		label: string | ReactNode;
		backgroundColor?: string;
		color?: string;
	};
	subcopy: string;
	supplementalCopy?: string;
	error?: string;
	checked?: boolean;
	clickCallback?: () => void;
	inputCallback?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	clearInput?: () => void;
	style?: Partial<CSSProperties>;
	method: (typeof SUBMISSION_METHODS)[keyof typeof SUBMISSION_METHODS];
};

const PrescriptionOption = forwardRef<HTMLInputElement, PrescriptionOptionProps>(
	({ title, lozenge, subcopy, checked, clickCallback, clearInput, inputCallback, supplementalCopy, method, error }, ref) => {
		const { locale } = useRouter();
		const isCartUsability = useFeatureIsOn('is-cart-usability') && locale === 'en-US';

		return (
			<Flex
				data-prescription-selection={method}
				data-testid={method}
				tabIndex={0}
				// eslint-disable-next-line max-len
				className={`${radioStyles.option} ${styles.option} ${isCartUsability && styles.usabilityWrapper} ${isCartUsability && checked && styles.checked}`}
				align='start'
				onClick={() => ((isCartUsability && method !== 'photo') || !isCartUsability) && clickCallback && clickCallback()}
				onKeyUp={e => {
					if (e.key !== 'Enter') return;
					clickCallback && clickCallback();
				}}
				style={{ borderRadius: '0.8rem', alignItems: 'flex-start', flex: isCartUsability ? '1' : 'auto' }} // TODO - remove inline styling and investigate "align" prop priority
			>
				{(!isCartUsability || method !== 'photo') && (
					<input
						style={{ display: 'none' }}
						type='file'
						accept={CART_USABILITY_ALLOWED_PRESCRIPTION_UPLOAD_EXTENSIONS.map(ext => `.${ext}`).join(', ')}
						onChange={e => {
							inputCallback(e);
						}}
						ref={ref}
						tabIndex={-1}
					/>
				)}
				{!isCartUsability && (
					<div
						style={{ marginTop: '-0.4rem' }}
						className={radioStyles.radioOuter}
						data-state={checked ? 'checked' : 'unchecked'}
					>
						<div className={radioStyles.radioInner}>
							<div className={radioStyles.radioDot}></div>
						</div>
					</div>
				)}
				{(!isCartUsability || method !== 'photo') && (
					<Flex fullWidth gap={1} column>
						<Title>{title}</Title>
						{lozenge?.label && (
							<Lozenge shape='square' backgroundColor={lozenge.backgroundColor} color={lozenge.color}>
								{lozenge.label}
							</Lozenge>
						)}
						<Paragraph>{subcopy}</Paragraph>
						{error && (
							<Caption small style={{ color: variables.red1 }}>
								{error}
							</Caption>
						)}
						{supplementalCopy && (
							<Flex fullWidth align='center' justify='between'>
								<Caption className={styles.fileName} small style={{ color: variables.gray4 }}>
									{supplementalCopy}
								</Caption>
								<Flex align='center' style={{ flexShrink: 0 }}>
									<Button
										linkStyle
										size='small'
										label='Upload New'
										onClick={() => {
											if (typeof ref === 'function') return;
											ref.current && ref.current.click();
										}}
									/>
									<Divider color={variables.gray2} margin='0.6rem' />
									<Trash
										callback={() => {
											clearInput();
										}}
									/>
								</Flex>
							</Flex>
						)}
					</Flex>
				)}
				{isCartUsability && method === 'photo' && (
					<DragAndDropFileUpload
						fileRef={ref}
						onFileSelect={inputCallback}
						accept={CART_USABILITY_ALLOWED_PRESCRIPTION_UPLOAD_EXTENSIONS}
						checked={checked}
					/>
				)}
			</Flex>
		);
	}
);

PrescriptionOption.displayName = 'PrescriptionOption';

export default PrescriptionOption;
