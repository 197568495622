import { createContext, Dispatch, useEffect, useReducer } from 'react';
import { QueryState } from '@tanstack/react-query';
import { FRAME_COLORS, LENS_COLORS } from '@constants';
import { NormalizedProduct, NormalizedVariant } from '@ts/product';
import { generateSunglassImages } from '@utils/images';
import { getVariantByOption, PRODUCT_TYPES } from '@utils/index';
import { Image } from '@ts/shopify-storefront-api';

export type BaseFrameContext = {
	product: NormalizedProduct;
	variant: NormalizedVariant;
	image: Image;
} & Partial<QueryState>;

type ActiveProductState = {
	product: NormalizedProduct;
	variant: NormalizedVariant;
};

export type BaseFrameState = ActiveProductState & {
	frame: FRAME_COLORS;
	lens?: LENS_COLORS;
	image: Image;
};

export type BaseFrameStateDispatch =
	| { type: 'frame'; option: FRAME_COLORS }
	| { type: 'lens'; option: LENS_COLORS }
	| { type: 'product'; option: { product: NormalizedProduct; variantIdentifier: string } };

export const BaseFrameContext = createContext<{ state: BaseFrameState; dispatch: Dispatch<BaseFrameStateDispatch> }>(null);

export const baseFrameReducer = (state: BaseFrameState, action: BaseFrameStateDispatch) => {
	try {
		switch (action.type) {
			case 'frame':
				return {
					...state,
					variant: getVariantByOption(state.product, action.option),
					frame: action.option,
					image: state.lens
						? generateSunglassImages({
								baseColor: action.option,
								lensColor: state.lens,
								name: state.product.name,
								length: 1,
							})[0]
						: getVariantByOption(state.product, action.option)?.image,
				} as BaseFrameState;
			case 'lens':
				return {
					...state,
					lens: action.option,
					image: generateSunglassImages({
						baseColor: state.frame,
						lensColor: action.option,
						name: state.product.name,
						length: 1,
					})[0],
				} as BaseFrameState;
			case 'product':
				const { product, variantIdentifier } = action.option;
				// handle setup
				if (product.handle.length < 1) {
					return state;
				}

				return {
					...state,
					product,
					frame: variantIdentifier,
					variant: getVariantByOption(product, variantIdentifier),
					image: state.lens
						? generateSunglassImages({
								baseColor: variantIdentifier as FRAME_COLORS,
								lensColor: state.lens,
								name: product.name,
								length: 1,
							})[0]
						: getVariantByOption(product, variantIdentifier)?.image,
				} as BaseFrameState;
			default:
				return state;
		}
	} catch (error) {
		console.error({ state, action });
		throw new Error(error);
	}
};

const useBaseFrame = (
	product: NormalizedProduct,
	variant?: NormalizedVariant,
	sunLensFlow?: boolean,
	initialLens?: LENS_COLORS,
	initialColor?: FRAME_COLORS
) => {
	const isBaseFrame = product?.handle?.length > 0 && product?.type?.includes(PRODUCT_TYPES.BASE_FRAME);

	const [state, dispatch] = useReducer(baseFrameReducer, {
		product,
		variant: variant ?? product.variants[0],
		frame: (initialColor ?? variant?.option ?? product.variants[0].option) as FRAME_COLORS,
		lens: sunLensFlow ? (initialLens ?? LENS_COLORS.BLACK) : null,
		image: variant?.image ?? product.variants[0].image,
	});

	useEffect(() => {
		if (isBaseFrame) {
			dispatch({
				type: 'product',
				option: { product, variantIdentifier: variant ? variant.option : product.variants[0].option },
			});
		}
	}, [isBaseFrame, product, variant]);

	return isBaseFrame ? ([state, dispatch] as const) : ([null, null] as const);
};

export default useBaseFrame;
