import { SHOP_PAGE_URL, SHOPIFY_CHECKOUT_URL } from '@constants';
import { NormalizedCart } from '@ts/cart';
import { Customer } from '@ts/shopify-storefront-api';

const fetchMultipassApi = async (cart: NormalizedCart, customer: Customer, callback?: (url: string) => void) => {
	try {
		const res = await fetch('/api/multipass', {
			method: 'POST',
			body: JSON.stringify({
				...customer,
				return_to: cart?.checkoutUrl.replace(SHOPIFY_CHECKOUT_URL, SHOP_PAGE_URL),
			}),
		});
		const { url } = await res.json();
		callback?.(url);
	} catch (error) {
		console.error(error);
	}
};
export default fetchMultipassApi;
