import { Fragment } from 'react';
import Link from 'next/link';
import cn from 'classnames';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useRouter } from 'next/router';
import { Chevron, DesktopSubNavIndicator, Flex, NavItemTypography } from '@components';
import { MenuGroupProps } from '@ts/contentful';
import SubMenuGroup from '../SubMenuGroup';
import styles from './MenuGroup.module.scss';

const MenuGroup = ({
	activeItem,
	header,
	index,
	menuGroup,
	debounceHover,
	toggleDesktopSubNav,
	toggleMobileSubNav,
}: MenuGroupProps) => {
	const { locale } = useRouter();
	const isLensesMenu = menuGroup.handle === 'lenses';
	const isV2Subscription = useFeatureIsOn('is-v2-subscription-test');
	const isAccesoriesMenu = menuGroup.handle === 'accessories' && isV2Subscription && locale === 'en-US';

	const isActive = activeItem === menuGroup.handle;
	const first = index + 1 === Number(header.dividerPosition);
	const last = index + 1 === header.menuGroups.length - 2;
	const divider =
		first || last || isAccesoriesMenu ? (
			<li key={`divider-${index}`} className={cn(styles.divider, { [styles['mobile-only']]: last })} />
		) : null;
	const indicator = menuGroup?.subMenuGroup ? (
		<>
			<Chevron direction='right' />
			<DesktopSubNavIndicator label='sub-nav-indicator' rotate={isActive} style={{ marginInline: '0.4rem' }} />
		</>
	) : null;

	if (isLensesMenu) {
		return null;
	}

	return (
		<Fragment key={`menu-group-${menuGroup.handle + index}`}>
			<li className={cn(styles['menu-group'])} data-handle={menuGroup.handle}>
				{!menuGroup.subMenuGroup ? (
					<Link href={menuGroup.link ?? '#'} className={styles['menu-group-text']}>
						<NavItemTypography>{menuGroup.text}</NavItemTypography>
					</Link>
				) : (
					<>
						<a
							type='button'
							aria-haspopup='true'
							aria-controls={`subnav-${menuGroup.handle}`}
							className={cn(styles['menu-group-text'], styles['has-sub-nav'])}
							tabIndex={0}
							onMouseEnter={() => {
								debounceHover(() => {
									toggleDesktopSubNav(true, menuGroup);
								});
							}}
							onClick={() => {
								toggleMobileSubNav(true, menuGroup);
							}}
						>
							<Flex align='center' justify='between' fullWidth className={styles['indicator-container']}>
								<NavItemTypography>{menuGroup.text}</NavItemTypography>
								{indicator}
							</Flex>
						</a>

						<SubMenuGroup
							menuGroup={menuGroup}
							isActive={isActive}
							toggleDesktopSubNav={toggleDesktopSubNav}
							toggleMobileSubNav={toggleMobileSubNav}
							debounceHover={debounceHover}
						/>
					</>
				)}
			</li>
			{divider}
		</Fragment>
	);
};

export default MenuGroup;
