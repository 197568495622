import variables from '@styles/export.module.scss';
import { InformationIcon, Popover } from '@components';
import { Caption } from '@components/typography';
import { FAQ_URL, PAIR_CARE_COPY } from '@constants';
import styles from './PaireCareTooltip.module.scss';

const PairCareToolTipContent = () => {
	return (
		<Caption>
			{PAIR_CARE_COPY} See our{' '}
			<a
				className='blue-text'
				target='_blank'
				style={{
					color: variables.blue2,
					textDecoration: 'underline',
				}}
				href={`${FAQ_URL}/articles/23239596609943-Warranty`}
			>
				FAQ
			</a>{' '}
			for more details.
		</Caption>
	);
};
export default function PairCareTooltip({ iconType = 'question' }: { iconType?: 'information' | 'question' }) {
	return (
		<Popover message={<PairCareToolTipContent />} extraClasses={styles.pairCarePopover} shouldHover={true}>
			{iconType === 'information' ? <InformationIcon width={12} color={variables.gray4} /> : null}
		</Popover>
	);
}
