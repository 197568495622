import { PRODUCT_TYPES } from '.';

export const getProductDataTag = (type: string, name: string) => {
	if (type?.includes(PRODUCT_TYPES.BASE_FRAME)) {
		return { 'data-base-frame-card': name };
	}
	if (type?.includes(PRODUCT_TYPES.TOP_FRAME)) {
		return { 'data-top-frame': name };
	}
	return null;
};
