import { useEffect, useState, useRef } from 'react';
import { m } from 'framer-motion';
import { useRouter } from 'next/router';
import { Bundle, CartMutationLineItem } from '@ts/cart';
import { Attention, Caption, CheckboxUpsell, determinePairCarePlan, Flex } from '@components';
import { slideDownAndFade } from '@utils/motions';
import { AttributeInput } from '@ts/shopify-storefront-api';
import variables from '@styles/export.module.scss';
import { NormalizedProduct, NormalizedVariant } from '@ts/product';
import { useIsMobile } from '@utils/hooks';
import { useCartContext } from '@context';
import { LOCALE_DICT } from '@constants';
import styles from './PairCare.module.scss';

type PairCareProps = {
	bundle: Bundle;
	pairCare: NormalizedProduct;
	callback?: (item: CartMutationLineItem) => void;
	isBuildFlow?: boolean;
};

const PairCare = ({ bundle, pairCare, callback, isBuildFlow = false }: PairCareProps) => {
	const isMobile = useIsMobile();
	const captionRef = useRef<HTMLDivElement>(null);
	const { isCartMutating, handleCartUpdate } = useCartContext();
	const savedPairCare = bundle?.base?.insurance?.variant?.price?.amount ?? 0;
	const [pairCarePlan, setPairCarePlan] = useState<NormalizedVariant>();
	const [pairCarePlanPrice, setPairCarePlanPrice] = useState({
		old: 0,
		new: savedPairCare,
	});
	const { locale } = useRouter();
	const currency = LOCALE_DICT[locale].currency;
	const insurance = bundle?.base?.insurance;
	const optimisticState = bundle?.optimistic;

	useEffect(() => {
		const tier = determinePairCarePlan(bundle, pairCare);
		if (tier) {
			setPairCarePlan(tier);
			if (!bundle.base.insurance && pairCarePlanPrice.old !== 0 && pairCarePlanPrice.new !== 0) {
				setPairCarePlanPrice({
					old: 0,
					new: 0,
				});

				return;
			}

			if (pairCarePlanPrice.new !== tier?.price?.amount) {
				setPairCarePlanPrice({
					old: pairCarePlanPrice.new,
					new: tier.price.amount,
				});
			}
		}
	}, [bundle]);

	useEffect(() => {
		if (
			!!pairCarePlan &&
			!isCartMutating &&
			!!bundle?.base?.insurance &&
			bundle?.base?.insurance?.variant?.id !== pairCarePlan.id
		) {
			const { insurance } = bundle.base;
			callback?.({
				...insurance,
				variant: pairCarePlan,
				quantity: 1,
				customAttributes: [
					...((insurance?.properties &&
						Object.entries(insurance.properties).map<AttributeInput>(([key, value]) => ({
							key,
							value,
						}))) ||
						[]),
				],
			});
			!callback &&
				handleCartUpdate([
					{
						...insurance,
						variant: pairCarePlan,
						quantity: 1,
						customAttributes: [
							...(insurance.properties &&
								Object.entries(insurance.properties).map<AttributeInput>(([key, value]) => ({
									key,
									value,
								}))),
						],
					},
				]);
		}
	}, [pairCarePlan, isCartMutating]);

	useEffect(() => {
		if (isMobile && captionRef.current && pairCarePlanPrice.new !== pairCarePlanPrice.old && pairCarePlanPrice.old !== 0) {
			captionRef?.current?.scrollIntoView({
				behavior: 'smooth',
				inline: 'center',
				block: 'center',
			});
		}
	}, [captionRef, pairCarePlanPrice, isMobile]);

	return (
		<div ref={captionRef}>
			<CheckboxUpsell
				base={bundle.base}
				line={insurance}
				product={pairCare}
				bundleKey={bundle.key}
				tooltip
				disabled={optimisticState}
				variant={pairCarePlan}
				callback={callback}
				isBuildFlow={isBuildFlow}
			/>
			{!!bundle?.base?.insurance && !!pairCarePlanPrice.old && pairCarePlanPrice.old !== pairCarePlanPrice.new && (
				<m.div className={styles.caption} {...slideDownAndFade}>
					<Flex align='center'>
						<Attention
							wrapperClass={styles.icon}
							height={20}
							width={20}
							fill={variables.gray3}
							label='Attention'
							color={variables.gray3}
						/>
						<Caption small style={{ color: variables.gray4 }}>
							The price of PairCare has been adjusted from {currency}
							{pairCarePlanPrice.old} to {currency}
							{pairCarePlanPrice.new} based on the cost of your glasses.
						</Caption>
					</Flex>
				</m.div>
			)}
		</div>
	);
};

export default PairCare;
