/* eslint-disable max-lines */
import { CUSTOMER_JOURNEYS, FRAME_COLOR_HANDLES, LENS_UPGRADES, PRODUCT_TYPES, RX_TYPE_ID, SUBMISSION_METHODS } from '@constants';
import { Bundle, NormalizedCart, NormalizedCartLine } from '@ts/cart';
import { handelize } from '@utils/strings';
import { NormalizedProduct } from '@ts/product';
import { CART_LINE_ATTRIBUTE_KEYS } from '@utils/constants/cart';
import { AttributeInput } from '@ts/shopify-storefront-api';
import { getPurchaseChecklist } from './cart-constants';

// TODO - possible to just make one `determineBlueLensPricing` ?
const determineBlueLensPricingCart = (id: RX_TYPE_ID, lines: NormalizedCartLine[]) => {
	const nonRx = handelize(id) === 'non-prescription';
	const addedBlueLight = lines.some(line => line.variant.handle === LENS_UPGRADES.BLUE_LIGHT_FILTERING);
	const numberOfLenses = lines.length;

	if (nonRx && (numberOfLenses === 0 || (addedBlueLight && numberOfLenses === 1))) {
		return 0;
	}
	return 49;
};

const checkBlueLightDiscount = (
	handle: string,
	prescriptionType: string,
	existingLenses: NormalizedCartLine[],
	defaultAmount: number
) => {
	// Render the discounted blue-light price if conditions are right
	const isBlueLight = handle === LENS_UPGRADES.BLUE_LIGHT_FILTERING;
	const possiblyDiscountedAmount = isBlueLight
		? determineBlueLensPricingCart(prescriptionType as RX_TYPE_ID, existingLenses)
		: defaultAmount;

	return possiblyDiscountedAmount;
};

const calculateOptimisticSubtotal = (oldData: NormalizedCart, newLines: NormalizedCartLine[]) => {
	if (!oldData.totalQuantity) {
		// adding to empty cart

		const baseLineItem = newLines.find(line => line.variant.type.includes(PRODUCT_TYPES.BASE_FRAME));
		const lensLineItems = newLines.filter(line => line.variant.type === PRODUCT_TYPES.LENS);

		return newLines.reduce(
			(res, cur) =>
				res +
				(baseLineItem
					? checkBlueLightDiscount(
							cur.variant.handle,
							// it's safe to access _bundle_key here because it always exists for lens products
							baseLineItem.properties._prescriptionType,
							lensLineItems,
							cur.variant.price.amount
						)
					: cur.variant.price.amount),
			0
		);
	}

	return newLines.reduce(
		(res, cur) =>
			res +
			checkBlueLightDiscount(
				cur.variant.handle,
				// it's safe to access _bundle_key here because it always exists for lens products
				oldData.bundles[cur.properties?._bundle_key]?.base?.prescription?.type ?? '',
				oldData.bundles[cur.properties?._bundle_key]?.base?.lenses ?? [],
				cur.quantity > 1 ? cur.variant.price.amount * cur.quantity : cur.variant.price.amount
			),
		0
	);
};

const computeBundleSubtotal = (bundle: Bundle) => {
	const {
		base: { frame, lenses },
	} = bundle;
	const baseFramePrice = frame.variant.price.amount;

	const lensPrice: number | undefined = lenses?.reduce(
		(res, cur) =>
			res +
			checkBlueLightDiscount(cur.variant.handle, frame.properties._prescriptionType, lenses, cur.variant.price.amount),
		0
	);

	return baseFramePrice + (lensPrice ?? 0);
};

const determinePairCarePlan = (bundle: Bundle, pairCare: NormalizedProduct) => {
	const bundleSubtotal = computeBundleSubtotal(bundle);
	return pairCare.variants.find(variant => {
		return (
			parseFloat(variant.metafields.minThreshold) <= bundleSubtotal &&
			parseFloat(variant.metafields.maxThreshold) >= bundleSubtotal
		);
	});
};

const getCollectionPathFromProperties = (properties: { [k: string]: string }) => {
	const customerJourney = properties._customerJourney;
	const customerType = properties._customerType;

	if (!customerJourney || !customerType) {
		return '';
	}

	return `/${customerJourney}/${customerType}/`;
};

const getCollectionPathFromCustomAttributes = (
	customAttributes: {
		key: string;
		value: string;
	}[]
) => {
	const customerJourney = customAttributes.find(attr => attr.key === '_customerJourney')?.value;
	const customerType = customAttributes.find(attr => attr.key === '_customerType')?.value;

	if (!customerJourney || !customerType) {
		return '';
	}

	return `/${customerJourney}/${customerType}/`;
};

/**
 * Returns the collection path from the cart line
 * In the cart `customAttributes` is transformed into properties
 * See utils/generate-bundle.ts
 * @param properties
 * @returns collection path
 */
const getCollectionPathFromCartLine = properties => {
	return properties?._collectionPath ?? getCollectionPathFromProperties(properties);
};

/**
 * Returns the collection path from the line item
 * Use this function when you have `customAttributes` previously to adding to cart
 * @param customAttributes
 * @returns collection path
 */
const getCollectionPathFromLineItem = customAttributes => {
	return (
		customAttributes?.find(attr => attr.key === '_collectionPath')?.value ??
		getCollectionPathFromCustomAttributes(customAttributes)
	);
};

function getEditPath(bundleKey, frameColor, properties, _customerJourney, handle, withSubscription) {
	let subRoute = 'eyeglasses';
	let queryParams = `?bundleKey=${bundleKey}&frameColor=${frameColor}&edit=true${
		withSubscription ? '&subscriptionPath=true' : ''
	}`;
	const demo = properties[CART_LINE_ATTRIBUTE_KEYS.CUSTOMER_TYPE];

	let parsedHandle = handle;

	if (Object.values(FRAME_COLOR_HANDLES).some(colorHandle => handle.includes(colorHandle))) {
		const colorHandle = Object.values(FRAME_COLOR_HANDLES).find(colorHandle => handle.includes(colorHandle));
		parsedHandle = parsedHandle.replace(`-${colorHandle}`, '');
	}

	switch (_customerJourney) {
		case CUSTOMER_JOURNEYS.SUNGLASSES:
			subRoute = 'sunglasses';
			queryParams += `&lensColor=${properties[CART_LINE_ATTRIBUTE_KEYS.LENS_COLOR]}`;
			break;
		case CUSTOMER_JOURNEYS.BLUELIGHT:
			subRoute = 'blue-light';
			break;
	}

	const basePath = `/${subRoute}/${demo}/${parsedHandle}`;
	return { basePath, queryParams };
}

function getReaderStrengthPayload(strength, frame, properties: { [k: string]: string }) {
	const cleanStr = strength.replace('+', '');

	return {
		...frame,
		customAttributes: [
			...(properties &&
				Object.entries(properties).map<AttributeInput>(([key, value]) => ({
					key,
					value,
				}))),
			{
				key: '_readerStrength',
				value: cleanStr,
			},
		],
	};
}

function getSubmissionMethodPayload(properties: { [k: string]: string }, submissionMethod, frame, extraProperties) {
	return {
		...frame,
		customAttributes: [
			...(properties &&
				Object.entries(properties).map<AttributeInput>(([key, value]) => ({
					key,
					value,
				}))),
			{
				key: CART_LINE_ATTRIBUTE_KEYS.SUBMISSION_METHOD,
				value: submissionMethod,
			},
			...(submissionMethod === SUBMISSION_METHODS.DOCTOR
				? Object.entries(extraProperties).map(([key, value]) => ({ key, value }))
				: []),
		],
	};
}

function getThreshold(locale, siteSettings) {
	if (!siteSettings) {
		return null;
	}
	if (locale === 'en-US') {
		return Number(siteSettings.usShippingThreshold);
	}
	if (locale === 'en-CA') {
		return Number(siteSettings.caShippingThreshold);
	}
	if (locale === 'en-GB') {
		return Number(siteSettings.ukShippingThreshold);
	}
	if (locale === 'en-AU') {
		return Number(siteSettings.auShippingThreshold);
	}
	if (locale === 'es-MX') {
		return Number(siteSettings.mxShippingThreshold);
	}
}

function getChecklist(cart, locale, siteSettings) {
	const threshold = getThreshold(locale, siteSettings);
	return getPurchaseChecklist(locale, threshold);
}

function getTopContainerClass(forceMobileStyle, styles) {
	return forceMobileStyle ? styles.topContainerInDrawer : styles.topContainer;
}

function hasDuplicatedKeys(cart: NormalizedCart): boolean {
	const { lines, bundles } = cart;
	const cleanBundles = { ...bundles };
	delete cleanBundles['no_bundle_key'];

	const bundleKeys = new Set(lines.map(line => line.properties[CART_LINE_ATTRIBUTE_KEYS.BUNDLE_KEY]));

	bundleKeys.delete('no_bundle_key');

	return bundleKeys.size !== Object.keys(bundles).length;
}

function getCartBreakdown(cart: NormalizedCart, isV2Subscription = false) {
	const topFrames = [];
	const bundles: Bundle[] = [];
	const others = [];
	const customBundles = {};
	const subscriptions = [];

	const processTop = (top: NormalizedCartLine, optimistic: boolean) => {
		if (top.properties._custom_bundle_key) {
			const key = top.properties._custom_bundle_key;
			customBundles[key] = customBundles[key] || [];
			customBundles[key].push({ optimistic, top });
			return;
		}

		if (top.sellingPlanAllocation) {
			const subOption = top.sellingPlanAllocation.sellingPlan.name.split(' - ')[0];
			top.variant.option = top.variant.option.includes('-') ? top.variant.option : `${top.variant.option} - ${subOption}`;
			top.variant.removedName = subOption;
			top.variant.type = PRODUCT_TYPES.TOPS_SUBSCRIPTION;
			if (!isV2Subscription) {
				top.variant.compareAtPrice = null;
				subscriptions.push({ optimistic, top });
			} else {
				topFrames.push({ optimistic, top });
			}
		} else {
			topFrames.push({ optimistic, top });
		}
	};

	const processBundle = (bundle: Bundle) => {
		const { other, optimistic, tops } = bundle;
		bundles.push({ ...bundle, subscriptionProduct: tops?.find(top => !!top.sellingPlanAllocation) });

		tops?.forEach(top => processTop(top, optimistic));
		other?.forEach(item => others.push({ optimistic, item }));
	};

	if (cart?.lines.length > 0) {
		Object.values(cart?.bundles).forEach(processBundle);
	}

	return { topFrames, bundles, others, customBundles, subscriptions };
}

function addCustomAttributes(attribute, frame, properties: { [k: string]: string }) {
	return {
		...frame,
		customAttributes: [
			...(properties &&
				Object.entries(properties).map<AttributeInput>(([key, value]) => ({
					key,
					value,
				}))),
			{ ...attribute },
		],
	};
}

function removeCustomAttributes(attribute, frame, properties: { [k: string]: string }) {
	return {
		...frame,
		customAttributes: Object.entries(properties)
			.map<AttributeInput>(([key, value]) => ({
				key,
				value,
			}))
			.filter(attr => attr.key !== attribute),
	};
}

export {
	calculateOptimisticSubtotal,
	checkBlueLightDiscount,
	computeBundleSubtotal,
	determineBlueLensPricingCart,
	determinePairCarePlan,
	getCollectionPathFromCartLine,
	getCollectionPathFromCustomAttributes,
	getCollectionPathFromLineItem,
	getCollectionPathFromProperties,
	getEditPath,
	getReaderStrengthPayload,
	getSubmissionMethodPayload,
	getThreshold,
	getChecklist,
	getTopContainerClass,
	hasDuplicatedKeys,
	getCartBreakdown,
	addCustomAttributes,
	removeCustomAttributes,
};
