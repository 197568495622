import { useRouter } from 'next/router';
import { CUSTOMER_JOURNEYS, LENSES_PACKAGES } from '@constants';
import { CheckboxUpsell, Loading, Flex, Paragraph, HeartSparkle } from '@components';
import { findLensPackageInLensOptions, LOCALE_DICT, useParseBaseFrameVariant } from '@utils/index';
import useBaseFrameVariant, {
	createBaseFrameVariant,
	useBaseFrameVariantPrices,
} from '@services/shopify/hooks/useBaseFrameVariant';
import { BASE_FRAME_LENS_OPTIONS } from '@utils/constants/base-skus';
import 'swiper/css';
import 'swiper/css/effect-fade';
import { Bundle } from '@ts/cart';
import styles from './LensUpsellSection.module.scss';

type LensUpsellSectionProps = {
	bundle: Bundle;
	isLoading: boolean;
};

const LensUpsellSection = ({ bundle, isLoading }: LensUpsellSectionProps) => {
	const router = useRouter();
	const { locale } = router;
	const countryCode = LOCALE_DICT[locale].countryCode;
	const { base, key: bundleKey } = bundle;
	const { frame } = base;
	const { variant, properties } = frame;
	const { option: fullVariantOption } = variant;
	const { _customerJourney } = properties;
	const isSunglasses = _customerJourney === CUSTOMER_JOURNEYS.SUNGLASSES;

	const { frameColor, lensType, rxType } = useParseBaseFrameVariant(fullVariantOption);
	const isLightResponsiveInCart = lensType.includes(BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE);

	const lensPack = !isSunglasses ? findLensPackageInLensOptions(lensType) : null;
	const BaseFrameVariant = useBaseFrameVariant({
		'handle': base.frame.variant.handle,
		'Color': frameColor,
		'Rx Type': rxType,
		'Lens': createBaseFrameVariant(lensType),
		'country': countryCode,
	});

	const { data: variantPrices } = useBaseFrameVariantPrices(base.frame.variant.handle, countryCode);

	if (!base) return null;
	if (isLoading || BaseFrameVariant.isLoading) return <Loading />;

	return (
		!isSunglasses && (
			<Flex column gap={4} maxWidth className={styles.container}>
				<Flex gap={2}>
					<HeartSparkle height={24} width={24} />
					<Paragraph className={styles.title}>Most Love Their Pair with These Lenses</Paragraph>
				</Flex>
				<CheckboxUpsell
					base={base}
					lensUpsell={BASE_FRAME_LENS_OPTIONS.BLUE_LIGHT}
					bundleKey={bundleKey}
					variantPrices={variantPrices}
					currentVariant={BaseFrameVariant}
					disabled={isLightResponsiveInCart}
					lensPack={lensPack}
				/>
				{lensPack !== LENSES_PACKAGES.BASIC && (
					<CheckboxUpsell
						base={base}
						lensUpsell={BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE}
						bundleKey={bundleKey}
						variantPrices={variantPrices}
						currentVariant={BaseFrameVariant}
						lensPack={lensPack}
					/>
				)}
			</Flex>
		)
	);
};

export default LensUpsellSection;
